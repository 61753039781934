import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalForm } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { updateOrderShippingAddress } from '../../../actions/shop/orders';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  shipping_address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  shipping_address_complement: {
    id: 'Address Complement',
    defaultMessage: 'Address Complement',
  },
  shipping_city: {
    id: 'City',
    defaultMessage: 'City',
  },
  shipping_country: {
    id: 'Country',
    defaultMessage: 'Country',
  },
  shipping_county: {
    id: 'County',
    defaultMessage: 'County',
  },
  shipping_email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  shipping_name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  shipping_organization: {
    id: 'Organization',
    defaultMessage: 'Organization',
  },
  shipping_phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  shipping_postcode: {
    id: 'Postcode',
    defaultMessage: 'Postcode',
  },
  shippingAddress: {
    id: 'Shipping Address',
    defaultMessage: 'Shipping Address',
  },
});

/**
 * OrderShippingAddressEditModal
 * @function OrderShippingAddressEditModal
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OrderShippingAddressEditModal = ({
  url,
  order,
  open,
  onOk,
  onCancel,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(null);
  const loading = useSelector((state) => state.orders.update?.loading);
  const loaded = useSelector((state) => state.orders.update?.loaded);
  if (open && updating && loaded) {
    setUpdating(false);
    onOk();
  }
  function onSubmit(shippingAddress) {
    dispatch(updateOrderShippingAddress(url, order.id, shippingAddress));
    setUpdating(true);
  }

  return open ? (
    <ModalForm
      open={open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      title={intl.formatMessage(messages.shippingAddress)}
      formData={order}
      schema={{
        fieldsets: [
          {
            id: 'default',
            title: intl.formatMessage(messages.default),
            fields: [
              'shipping_name',
              'shipping_organization',
              'shipping_phone',
              'shipping_email',
              'shipping_address',
              'shipping_address_complement',
              'shipping_postcode',
              'shipping_city',
              'shipping_county',
              'shipping_country',
            ],
          },
        ],
        properties: {
          shipping_name: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_name),
          },
          shipping_organization: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_organization),
          },
          shipping_phone: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_phone),
          },
          shipping_email: {
            type: 'string',
            widget:' email',
            title: intl.formatMessage(messages.shipping_email),
          },
          shipping_address: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_address),
          },
          shipping_address_complement: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_address_complement),
          },
          shipping_postcode: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_postcode),
          },
          shipping_city: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_city),
          },
          shipping_county: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_county),
          },
          shipping_country: {
            type: 'string',
            title: intl.formatMessage(messages.shipping_country),
          },
        },
        required: [],
      }}
    />
  ) : null;
};

OrderShippingAddressEditModal.propTypes = {
  url: PropTypes.string.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    shipping_name: PropTypes.string,
    shipping_organization: PropTypes.string,
    shipping_phone: PropTypes.string,
    shipping_email: PropTypes.string,
    shipping_address: PropTypes.string,
    shipping_address_complement: PropTypes.string,
    shipping_postcode: PropTypes.string,
    shipping_city: PropTypes.string,
    shipping_county: PropTypes.string,
    shipping_country: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default OrderShippingAddressEditModal;
