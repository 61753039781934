import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const messages = defineMessages({
  addDates: {
    id: 'Add dates',
    defaultMessage: 'Add dates',
  },
  day: {
    id: 'Day',
    defaultMessage: 'Day',
  },
  fromDate: {
    id: 'From Date',
    defaultMessage: 'From Date',
  },
  repeatEveryYear: {
    id: 'Repeat every year',
    defaultMessage: 'Repeat every year',
  },
  toDate: {
    id: 'To Date',
    defaultMessage: 'To Date',
  },
});

const datestableSchemaExtender = (schema, data, intl) => {
  const mutated = structuredClone(schema);
  console.log('group title should be', data.name);
  mutated.title = data.name;
  return mutated;
};

const datestableSchema = (intl) => ({
  title: 'Dates',
  addMessage: intl.formatMessage(messages.addDates),
  properties: {
    fromDate: {
      title: intl.formatMessage(messages.fromDate),
      type: 'date',
    },
    toDate: {
      title: intl.formatMessage(messages.toDate),
      type: 'date',
    },
    repeatEveryYear: {
      title: intl.formatMessage(messages.repeatEveryYear),
      type: 'boolean',
    },
    day: {
      title: intl.formatMessage(messages.day),
      type: 'string',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['fromDate', 'toDate', 'repeatEveryYear', 'day'],
    },
  ],
  required: [],
});

const DatestableWidget = (props) => {
  const intl = useIntl();

  // props.value?.items || props.default?.items || []
  const value = [];

  return (
    <ObjectListWidget
      schema={datestableSchema(intl)}
      schemaExtender={datestableSchemaExtender}
      {...props}
      value={value}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default DatestableWidget;
