import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalForm } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { updateOrderItem } from '../../../actions/shop/orders';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  quantity: {
    id: 'Quantity',
    defaultMessage: 'Quantity',
  },
});

/**
 * OrderItemEditModal
 * @function OrderItemEditModal
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OrderItemEditModal = ({
  url,
  order,
  item,
  open,
  onOk,
  onCancel,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(null);
  const loading = useSelector((state) => state.orders.update?.loading);
  const loaded = useSelector((state) => state.orders.update?.loaded);
  const shopUrl = useSelector((state) => state.orders.shopUrl);
  if (item) {
    item = { ...item, quantity: parseInt(item.quantity) };
  }
  if (open && updating && loaded) {
    setUpdating(false);
    onOk();
  }
  function onSubmit(item) {
    dispatch(updateOrderItem(url, order.id, item));
    setUpdating(true);
  }

  return open ? (
    <ModalForm
      open={open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      title={intl.formatMessage(messages.item)}
      formData={item}
      schema={{
        fieldsets: [
          {
            id: 'default',
            title: intl.formatMessage(messages.default),
            fields: ['item', 'quantity'],
          },
        ],
        properties: {
          item: {
            type: 'string',
            widget: 'autocomplete',
            widgetOptions: {
              vocabulary: {
                '@id': `${shopUrl}/@vocabularies/kg.cart.orderitems`,
              },
            },
            required: true,
            title: intl.formatMessage(messages.item),
          },
          quantity: {
            type: 'integer',
            required: true,
            title: intl.formatMessage(messages.quantity),
          },
        },
        required: ['item'],
      }}
    />
  ) : null;
};

//            vocabulary: { '@id': 'http://localhost:3000/www_apanymantel_com/web-pasteles/pastelerias/kortezubi/pasteleria-el-dulzon/@vocabularies/kg.cart.orderitems' },

OrderItemEditModal.propTypes = {
  url: PropTypes.string.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
  }),
  item: PropTypes.shape({
    _id: PropTypes.number,
    item: PropTypes.string,
    quantity: PropTypes.number,
  }),
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default OrderItemEditModal;
