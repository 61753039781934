/**
 * ShippingAgentSettingsEdit component.
 * @module components/manage/ShippingAgentsSettings/ShippingAgentSettingsEdit
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Segment, Table } from 'semantic-ui-react';
import qs from 'query-string';
import {
  Form,
  Icon as IconNext,
  Toast,
  Toolbar,
} from '@plone/volto/components';
import { getBaseUrl, Helmet } from '@plone/volto/helpers';
import { toast } from 'react-toastify';
import backSVG from '@plone/volto/icons/back.svg';
import {
  getShippingAgentSettings,
  updateShippingAgentSettings,
} from '../../../actions/shop/shipping_agents_settings';
import { isEmpty } from 'lodash';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  editShippingAgentSettings: {
    id: 'Edit Shipping Agent Settings for shop {shop}',
    defaultMessage: 'Edit Shipping Agent Settings for shop {shop}',
  },
  shippingAgentsSettings: {
    id: 'Shipping Agents Settings',
    defaultMessage: 'Shipping Agents Settings',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  successContent: {
    id: 'Shipping Agent Settings correctly saved.',
    defaultMessage: 'Shipping Agent Settings correctly saved.',
  },
});

/**
 * Component to edit the annotations.
 * @function ShippingAgentSettingsEdit
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const ShippingAgentSettingsEdit = ({ location }) => {
  const { pathname } = location;
  const shippingAgentId = qs.parse(location.search).id;
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  const [isClient, setIsClient] = useState(null);
  const shippingAgent = useSelector(
    (state) => state.shippingAgentsSettings.shippingAgentSettings,
  );
  const title = useSelector((state) => state.content.data.title);
  useEffect(() => setIsClient(true), []);
  useEffect(
    () =>
      dispatch(getShippingAgentSettings(getBaseUrl(pathname), shippingAgentId)),
    [pathname, dispatch, shippingAgentId],
  );

  function submit(formData) {
    if (!isEmpty(formData)) {
      dispatch(
        updateShippingAgentSettings(
          getBaseUrl(pathname),
          shippingAgentId,
          formData,
        ),
      );
      toast.success(
        <Toast
          success
          title={intl.formatMessage(messages.success)}
          content={intl.formatMessage(messages.successContent)}
        />,
      );
    }
  }

  function cancel() {
    history.push(`${getBaseUrl(pathname)}/shipping-agents-settings`);
  }

  return (
    <Container id="page-shipping-agents">
      <Helmet title={intl.formatMessage(messages.shippingAgentsSettings)} />
      <Segment.Group raised>
        <Segment className="primary">
          <FormattedMessage
            id="Shipping Agents Settings of {title}"
            defaultMessage="Shipping Agents Settings of {title}"
            values={{
              title: <q>{title}</q>,
            }}
          />
        </Segment>
      </Segment.Group>
      {shippingAgent && (
        <Form
          title={intl.formatMessage(messages.editShippingAgentSettings, {
            shop: title,
          })}
          schema={shippingAgent.schema}
          formData={shippingAgent.data || {}}
          pathname={pathname}
          onSubmit={submit}
          onCancel={cancel}
          isEditForm={true}
        />
      )}
      {isClient &&
        createPortal(
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to={`${getBaseUrl(pathname)}`} className="item">
                <IconNext
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />,
         document.getElementById('toolbar'),
       )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ShippingAgentSettingsEdit.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default ShippingAgentSettingsEdit;
