import config from '@plone/volto/registry';

// Non Content Routes/Views
// You can include either RegEx or a string representing the ending of the
// nonContentRoute eg. '/add' will match '/foo/bar/add'
export const nonContentRoutes = [
  '/myshops',
  '/orders',
  '/order',
  '/shipping-agents-settings',
  '/shipping-agent-settings-edit',
];
