/**
 * OrderView component.
 * @module components/manage/OrderView/OrderView
 */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import {
  Button,
  Confirm,
  Container,
  Dimmer,
  Divider,
  Dropdown,
  Grid,
  Header,
  Input,
  Item,
  Loader,
  Menu,
  Popup,
  Segment,
  Select,
  Table,
  Step,
} from 'semantic-ui-react';
import { createPortal } from 'react-dom';
import {
  ContentsBreadcrumbs,
  DatetimeWidget,
  FormattedDate,
  SelectWidget,
  Toolbar,
  Icon,
} from '@plone/volto/components';
import { getBaseUrl, Helmet } from '@plone/volto/helpers';
import { getOrder, listOrderStates } from '../../../actions/shop/orders';
import backSVG from '@plone/volto/icons/back.svg';
import worldSVG from '@plone/volto/icons/world.svg';
import configSVG from '@plone/volto/icons/configuration.svg'
import userSVG from '@plone/volto/icons/user.svg'
import trolleyFullSVG from '@plone/volto/icons/trolley-full.svg'
import penSVG from '@plone/volto/icons/pen.svg'
import alertSVG from '@plone/volto/icons/alert.svg'
import delightedSVG from '@plone/volto/icons/delighted.svg'
import enterpriseSVG from '@plone/volto/icons/enterprise.svg'
import pencilSVG from '@plone/volto/icons/pencil.svg'
import deleteSVG from '@plone/volto/icons/delete.svg'
import clockSVG from '@plone/volto/icons/clock.svg'
import OrderCommentEditModal from './OrderCommentEditModal';
import OrderPickupAddressEditModal from './OrderPickupAddressEditModal';
import OrderShippingAddressEditModal from './OrderShippingAddressEditModal';
import OrderBuyerInfoEditModal from './OrderBuyerInfoEditModal';
import './OrderView.css';
import { deleteOrderItem } from '../../../actions/shop/orders';
import OrderItemEditModal from './OrderItemEditModal';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  confirmDeleteItem: {
    id: 'Confirm to delete item?',
    defaultMessage: 'Confirm to delete item?',
  },
  delivery: {
    id: 'Delivery',
    defaultMessage: 'Delivery',
  },
  order: {
    id: 'Order',
    defaultMessage: 'Order',
  },
  pickup: {
    id: 'Pickup shop',
    defaultMessage: 'Pickup shop',
  },
});

/**
 * Component to edit a order.
 * @function OrderView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OrderView = ({ location }) => {
  const { pathname } = location;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    showEditComment: false,
    showEditPickupAddress: false,
    showEditShippingAddress: false,
    showEditBuyerInfo: false,
    showEditItem: false,
    editItem: null,
    showConfirmDeleteItem: false,
    deleteItem: null,
    isClient: false,
  });
  const baseUrl = getBaseUrl(pathname);
  const orderId = qs.parse(location.search).id;
  const order = useSelector((state) => state.orders.order);
  const loading = useSelector((state) => state.orders.loading);
  const orderStates = useSelector((state) => state.orders.orderStates);
  const permissionToEdit = useSelector(
    (state) => state.orders.permissionToEdit,
  );
  const pickupAddresses = useSelector((state) => state.orders.pickupAddresses);
  const currency = 'EUR';

  useEffect(() => setState({ ...state, isClient: true }), []);
  useEffect(() => dispatch(listOrderStates(baseUrl)), [dispatch, baseUrl]);
  useEffect(() => {
    dispatch(getOrder(baseUrl, orderId));
  }, [dispatch, baseUrl, orderId]);

  function getDeliverOrPickup(delOrPic) {
    switch (delOrPic) {
      case 'delivery':
        return intl.formatMessage(messages.delivery);
      case 'pickup':
        return intl.formatMessage(messages.pickup);
    }
  }

  function getOrderStateTitle(orderStateToken) {
    if (orderStates) {
      const orderState = orderStates.filter((os) => os.id === orderStateToken);
      if (orderState.length > 0) {
        return orderState[0].title;
      }
    }
  }

  function getPickupAddress(pickupAddressUid) {
    const pickupAddress = pickupAddresses.filter(
      (pa) => pa.uid === pickupAddressUid,
    );
    if (pickupAddress.length > 0) {
      return pickupAddress[0].name;
    }
  }

  function onCancelEditComment() {
    setState({ ...state, showEditComemnt: false });
  }

  function onOkEditComment() {
    setState({ ...state, showEditComment: false });
  }

  function onCancelEditPickupAddress() {
    setState({ ...state, showEditPickupAddress: false });
  }

  function onOkEditPickupAddress() {
    setState({ ...state, showEditPickupAddress: false });
  }

  function onCancelEditShippingAddress() {
    setState({ ...state, showEditShippingAddress: false });
  }

  function onOkEditShippingAddress() {
    setState({ ...state, showEditShippingAddress: false });
  }

  function onCancelEditBuyerInfo() {
    setState({ ...state, showEditBuyerInfo: false });
  }

  function onOkEditBuyerInfo() {
    setState({ ...state, showEditBuyerInfo: false });
  }

  function onCancelEditItem() {
    setState({ ...state, showEditItem: false, editItem: null });
  }

  function onOkEditItem() {
    setState({ ...state, showEditItem: false, editItem: null });
  }

  function showConfirmDeleteItem(item) {
    setState({ ...state, showConfirmDeleteItem: true, deleteItem: item });
  }

  function onConfirmDeleteItem() {
    dispatch(deleteOrderItem(baseUrl, order.id, state.deleteItem));
    setState({ ...state, showConfirmDeleteItem: false, deleteItem: null });
  }

  function onCancelDeleteItem() {
    setState({ ...state, showConfirmDeleteItem: false, deleteItem: null });
  }

  return (
    <Container id="page-order">
      <Helmet title={intl.formatMessage(messages.order)} />
      {state.isClient &&
        createPortal(
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to={`${getBaseUrl(pathname)}`} className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />,
          document.getElementById('toolbar'),
        )}
    <Dimmer.Dimmable as="div" blurring dimmed={loading}>
    <Dimmer active={loading} inverted>
        <Loader indeterminate size="massive">
        <FormattedMessage id="Loading" defaultMessage="Loading" />
        </Loader>
    </Dimmer>
    <article>
        {order && permissionToEdit && (
        <>
            <OrderCommentEditModal
            url={baseUrl}
            order={order}
            open={state.showEditComment}
            onCancel={onCancelEditComment}
            onOk={onOkEditComment}
            />
            <OrderPickupAddressEditModal
            url={baseUrl}
            order={order}
            open={state.showEditPickupAddress}
            onCancel={onCancelEditPickupAddress}
            onOk={onOkEditPickupAddress}
            />
            <OrderShippingAddressEditModal
            url={baseUrl}
            order={order}
            open={state.showEditShippingAddress}
            onCancel={onCancelEditShippingAddress}
            onOk={onOkEditShippingAddress}
            />
            <OrderBuyerInfoEditModal
            url={baseUrl}
            order={order}
            open={state.showEditBuyerInfo}
            onCancel={onCancelEditBuyerInfo}
            onOk={onOkEditBuyerInfo}
            />
            <OrderItemEditModal
            url={baseUrl}
            order={order}
            item={state.editItem}
            open={state.showEditItem}
            onCancel={onCancelEditItem}
            onOk={onOkEditItem}
            />
            <Confirm
            open={state.showConfirmDeleteItem}
            header={intl.formatMessage(messages.confirmDeleteItem)}
            content={
                <div className="content">{state.deleteItem?.description}</div>
            }
            onCancel={onCancelDeleteItem}
            onConfirm={onConfirmDeleteItem}
            size="mini"
            />
        </>
        )}
        <section id="content-core">
        {order && (
            <Fragment>
            <Header as="h1">
                <Header.Content>
                Order number {order.id}
                <Header.Subheader>
                    created &nbsp;
                    <FormattedDate date={order.order_datetime} includeTime />
                </Header.Subheader>
                </Header.Content>
            </Header>

                {/* ORDER CONFIGURATION */}

                <div className="order-configuration order-block">
                  <Header as="h3" block>
                    <Icon name={configSVG} />
                    <FormattedMessage
                      id="Order configuration"
                      defaultMessage="Order configuration"
                    />
                  </Header>
                  <div>
                    {order.deliver_or_pickup === 'pickup' && (
                      <p className="strong">
                        <FormattedMessage
                          id="Order for pickup"
                          defaultMessage="This order is for PICKUP at the shop"
                        />
                      </p>
                    )}
                    {order.deliver_or_pickup === 'delivery' && (
                      <div>
                        <p className="strong">
                          <FormattedMessage
                            id="Order for delivery"
                            defaultMessage="This order is for DELIVERY"
                          />
                        </p>
                        <p className="strong">
                          <FormattedMessage
                            id="Delivery by"
                            defaultMessage="The delivery will be done by:"
                          />
                        </p>
                        &nbsp;
                        {order.shipping_method}
                      </div>
                    )}

                    <div>
                      <p>
                        <span className="strong">
                          <FormattedMessage
                            id="Delivery date is"
                            defaultMessage="The delivery date is: "
                          />
                        </span>
                        <FormattedDate
                          date={order.delivery_pickup_datetime}
                          includeTime
                        />
                      </p>
                    </div>
                    {order.payment_method === 'kg.payment.cod_delivery' && (
                      <p className="strong">
                        <FormattedMessage
                          id="Pending of payment"
                          defaultMessage="This order must be charged upon delivery"
                        />
                      </p>
                    )}

                    <div>
                      Payment status: Order is paid / Order is pending of
                      payment
                    </div>
                    <div>
                      The payment method was:
                      <p>{order.payment_method}</p>
                    </div>
                  </div>
                </div>

                {/* DELIVERY / PICKUP  ADDRESS */}

                {order.deliver_or_pickup === 'pickup' && (
                  <div className="delivery-address order-block">
                    <Header as="h3" block>
                      <Icon name={enterpriseSVG} />
                      <FormattedMessage
                        id="Pickup shop"
                        defaultMessage="Pickup shop"
                      />
                    </Header>
                    <div>
                      <span>{getPickupAddress(order.pickup_address)}</span>
                      {permissionToEdit && (
                        <Button
                          onClick={() =>
                            setState({ ...state, showEditPickupAddress: true })
                          }
                          icon
                          size="small"
                          className="edit-btn"
                        >
                          <Icon name={pencilSVG} />
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {order.deliver_or_pickup === 'delivery' && (
                  <div className="delivery-address order-block">
                    <Header as="h3" block>
                      <Icon name={worldSVG} />
                      <FormattedMessage
                        id="Delivery address"
                        defaultMessage="Delivery address"
                      />
                    </Header>
                    <div>
                      <div className="inline-block">
                        <p>{order.shipping_organization}</p>
                        <p>
                          {order.shipping_address} -
                          {order.shipping_address_complement}
                        </p>
                        <p>
                          {order.shipping_postcode} - {order.shipping_city} -
                          {order.shipping_county}
                        </p>
                      </div>
                      {permissionToEdit && (
                        <Button
                          onClick={() =>
                            setState({
                              ...state,
                              showEditShippingAddress: true,
                            })
                          }
                          icon
                          size="small"
                          className="edit-btn"
                        >
                          <Icon name={pencilSVG} />
                        </Button>
                      )}
                    </div>
                  </div>
                )}

                {/* BUYER INFORMATION */}

                <div className="buyer-info order-block">
                  <Header as="h3" block>
                    <Icon name={userSVG} />
                    <FormattedMessage
                      id="Buyer info"
                      defaultMessage="Buyer information"
                    />
                  </Header>
                  <div className="inline-block">
                    <p>{order.billing_name}</p>
                    <p>{order.billing_phone}</p>
                    <p>{order.billing_email}</p>
                  </div>
                  {permissionToEdit && (
                    <Button
                      onClick={() =>
                        setState({ ...state, showEditBuyerInfo: true })
                      }
                      icon
                      size="small"
                      className="edit-btn"
                    >
                      <Icon name={pencilSVG} />
                    </Button>
                  )}
                </div>

                {/* RECIPIENT INFORMATION */}

                <div className="recipient-info order-block">
                  <Header as="h3" block>
                    <Icon name={delightedSVG} />
                    <FormattedMessage
                      id="Recipient info"
                      defaultMessage="Receiver information"
                    />
                  </Header>
                  <div className="inline-block">
                    <p>{order.shipping_name}</p>
                    <p>{order.shipping_phone}</p>
                    <p>{order.shipping_email}</p>
                  </div>
                  {permissionToEdit && (
                    <Button
                      onClick={() =>
                        setState({ ...state, showEditShippingAddress: true })
                      }
                      icon
                      size="small"
                      className="edit-btn"
                    >
                      <Icon name={pencilSVG} />
                    </Button>
                  )}
                </div>

                {/* GIFT MESSAGE AREA */}

                <div className="gift-message order-block">
                  <Header as="h3" block>
                    <Icon name={penSVG} />
                    <FormattedMessage
                      id="Giftcard Message"
                      defaultMessage="Giftcard Message"
                    />
                  </Header>
                  <div className="inline-block">
                    {order._annotations.apanymantel.giftMessage}
                  </div>
                  {/*
                  {permissionToEdit && (
                    <Button
                            icon
                            size="small"
                            className="edit-btn"
                    >
                      <Icon name={pencilSVG} />
                    </Button>
                  )}
                   */}
                </div>

                {/* SHOP ALERT AREA */}

                <div className="shop-message order-block">
                  <Header as="h3" block>
                    <Icon name={alertSVG} />
                    <FormattedMessage
                      id="Shop & Delivery Message"
                      defaultMessage="Shop & Delivery Message"
                    />
                  </Header>
                  <div className="inline-block">{order.comment}</div>
                  {permissionToEdit && (
                    <Button
                      onClick={() =>
                        setState({ ...state, showEditComment: true })
                      }
                      icon
                      size="small"
                      className="edit-btn"
                    >
                      <Icon name={pencilSVG} />
                    </Button>
                  )}
                </div>

                {/* PRODUCTS  */}

                <div className="products order-block">
                  <Header as="h3" block>
                    <Icon name={trolleyFullSVG} />
                    <FormattedMessage
                      id="Ordered products"
                      defaultMessage="ordered products"
                    />
                  </Header>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <FormattedMessage id="Photo" defaultMessage="Photo" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <FormattedMessage
                            id="Item Name"
                            defaultMessage="Item Name"
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <FormattedMessage
                            id="Amount"
                            defaultMessage="Amount"
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <FormattedMessage
                            id="Item unit price"
                            defaultMessage="Item unit price"
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          <FormattedMessage id="Total" defaultMessage="Total" />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {order.order_items.map((order_item) => (
                        <Table.Row key={order_item._id}>
                          <Table.Cell>{order_item.picture}</Table.Cell>
                          <Table.Cell>{order_item.description}</Table.Cell>
                          <Table.Cell>
                            <FormattedNumber value={order_item.quantity} />
                          </Table.Cell>
                          <Table.Cell>
                            <FormattedNumber
                              value={order_item.unit_price}
                              style="currency"
                              currency={currency}
                            />
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            <FormattedNumber
                              value={order_item.amount}
                              style="currency"
                              currency={currency}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            {permissionToEdit && (
                              <>
                                <Button
                                  onClick={() =>
                                    showConfirmDeleteItem(order_item)
                                  }
                                  icon
                                  size="small"
                                  className="edit-btn"
                                >
                                  <Icon name={deleteSVG} />
                                </Button>
                                <Button
                                  onClick={() =>
                                    setState({
                                      ...state,
                                      showEditItem: true,
                                      editItem: order_item,
                                    })
                                  }
                                  icon
                                  size="small"
                                  className="edit-btn"
                                >
                                  <Icon name={pencilSVG} />
                                </Button>
                              </>
                            )}
                          </Table.Cell>
                          {/* {order_item.details} */}
                          {/* <FormattedNumber */}
                          {/*   value={order_item.vat_rate / 100} */}
                          {/*   style="percent" */}
                          {/* /> */}
                        </Table.Row>
                      ))}
                      <Table.Row>
                        <Table.Cell>
                          <FormattedMessage
                            id="Subtotal"
                            defaultMessage="Subtotal"
                          />
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell>
                          {order.order_items.reduce(
                            (acc, order_item) =>
                              acc + parseInt(order_item.quantity),
                            0,
                          )}
                        </Table.Cell>
                        <Table.Cell colSpan="2" textAlign="right"></Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell>
                          <FormattedMessage
                            id="Price of delivery"
                            defaultMessage="Price of delivery"
                          />
                        </Table.Cell>
                        <Table.Cell colSpan="4" textAlign="right">
                          <FormattedNumber
                            value={order.shipping_method_amount}
                            style="currency"
                            currency={currency}
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell>
                          <FormattedMessage id="Total" defaultMessage="Total" />
                        </Table.Cell>
                        <Table.Cell colSpan="4" textAlign="right"></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>

                {/* ORDER STATUS AREA */}

                <div className="timeline order-block">
                  <Header as="h3" block>
                    <Icon name={clockSVG} />
                    <FormattedMessage id="Timeline" defaultMessage="Timeline" />
                  </Header>
                  <Step.Group>
                    {order.order_state_history.map((order_state) => (
                      <Step key={order_state._id}>
                        {/*<Icon name={worldSVG} />*/}
                        <Step.Content>
                          <Step.Title>
                            {getOrderStateTitle(order_state.state)}
                          </Step.Title>
                          <Step.Description>
                            <FormattedDate
                              date={order_state.datetime}
                              includeTime
                            />
                          </Step.Description>
                        </Step.Content>
                      </Step>
                    ))}
                  </Step.Group>
                </div>
              </Fragment>
            )}
          </section>
        </article>
      </Dimmer.Dimmable>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OrderView.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default OrderView;
