/**
 * Prices view component.
 * @module components/theme/View/Prices
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  Checkbox,
  Container,
  Dimmer,
  Form,
  Image,
  Input,
  Loader,
  Menu,
  Segment,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon, Pagination, Toast } from '@plone/volto/components';
import { searchContent, updateContent } from '@plone/volto/actions';
import './Prices.css';
import { GroupOptionPricesWidget } from '../..';
import ArticleCategoriesFilter from './ArticleCategoriesFilter';
import { getArticleCategories } from '../../../actions/shop/categories';
import {
  activateArticle,
  deactivateArticle,
} from '../../../actions/shop/articles';
import { isEqual, pickBy } from 'lodash';
import zoomSVG from '@plone/volto/icons/zoom.svg';

const messages = defineMessages({
  activateDeactivateIndefinite: {
    id: 'Enable Disable indefinitely',
    defaultMessage: 'Disable / Enable indefinitely',
  },
  activateProductImmediately: {
    id: 'Enable product immediately',
    defaultMessage: 'Enable product immediately',
  },
  deactivateProductImmediately: {
    id: 'Disable product',
    defaultMessage: 'Disable product',
  },
  filter: {
    id: 'Search product...',
    defaultMessage: 'Search product...',
  },
  filtering: {
    id: 'Filtering by',
    defaultMessage: 'Filtering by',
  },
  helpSeeAllProducts: {
    id: 'Help See All Products',
    defaultMessage: 'By default you see only the published products, toggle to see all products',
  },
  priceUpdated: {
    id: 'Price updated',
    defaultMessage: 'Price updated',
  },
  searchArticles: {
    id: 'Search Articles',
    defaultMessage: 'Search Articles',
  },
  seeAllProducts: {
    id: 'See all products',
    defaultMessage: 'See all products',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
});

/**
 * Prices view component class.
 * @function Prices
 * @params {object} content Content object.
 * @return {string} Markup of the component .
 */
const Prices = ({ data, pathname }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [articles, setArticles] = useState([]);
  const [state, setState] = useState({
    currentPage: 0,
    pageSize: 15,
    reviewState: 'published',
    filter: '',
    filteringBy: '',
    selectedCategory: null,
  });
  let filterTimeout = null;

  const loading = useSelector(
    (state) => state.search.subrequests.articlesList?.loading,
  );
  const articlesListResult = useSelector(
    (state) => state.search.subrequests.articlesList,
  );
  const categories = useSelector(
    (state) => state.article_categories.article_categories,
  );

  useEffect(() => {
    setArticles({ ...articlesListResult });
  }, [articlesListResult]);

  function onChangePage(event, { value }) {
    setState({
      ...state,
      currentPage: value,
    });
  }

  function onChangePageSize(event, { value }) {
    setState({
      ...state,
      pageSize: value,
      currentPage: 0,
    });
  }

  function fetchArticles(fullobjects, subquery) {
    let query = {
      portal_type: ['apanymantel.cart.shopping.Article'],
      'path.depth': 2, // only second level
      fullobjects,
      b_size: state.pageSize,
      b_start: state.pageSize * state.currentPage,
      ...(state.filter && { Title: `*${state.filter}*` }),
    };
    if (state.selectedCategory) {
      query.category = state.selectedCategory;
    }
    if (state.reviewState) {
      query.review_state = state.reviewState;
    }

    dispatch(searchContent(pathname, query, subquery));
  }
  useEffect(() => {
    dispatch(getArticleCategories(pathname));
  }, [dispatch, pathname]);

  useEffect(() => {
    fetchArticles(true, 'articlesList');
  }, [
    state.pageSize,
    state.currentPage,
    dispatch,
    state.selectedCategory,
    state.filteringBy,
    state.reviewState,
  ]);

  // useEffect(() => {
  //   fetchArticles(true, 'articles');
  // }, [state.pageSize, state.currentPage]);

  /**
   * Change Price
   * @function changePrice
   * @param {string} id
   * @param {string} value
   * @param {string} oldValue
   * @param {object} item
   * @return {undefined}
   */
  function changePrice(id, value, oldValue, item) {
    // remove undefined options
    const valueNotUndefined = {
      ...value,
      options: {
        items: value.options.items.map((op) =>
          pickBy(op, (v) => v !== undefined),
        ),
      },
    };
    const options = {
      items: item.options.items.map((groupOption) =>
        groupOption['@id'] === id ? valueNotUndefined : groupOption,
      ),
    };
    if (!isEqual(options, item.options)) {
      dispatch(updateContent(flattenToAppURL(item['@id']), { options }))
        .then((resp) => {
          toast.success(
            <Toast
              success
              title={intl.formatMessage(messages.success)}
              content={intl.formatMessage(messages.priceUpdated)}
            />,
            { autoClose: 2000 },
          );
          setArticles({
            ...articles,
            items: articles.items.map((item2) => ({
              ...item2,
              options: item2['@id'] === item['@id'] ? options : item2.options,
            })),
          });
        })
        .catch((error) => {
          // restore original options
          setArticles({ ...articlesListResult });
          const shadowedError = JSON.parse(error.response.text);
          toast.error(
            <Toast
              error
              title={shadowedError.type}
              content={shadowedError.message}
            />,
            { toastId: 'updateFailed' },
          );
        });
    }
  }

  /**
   * Select Category
   * @function selectCategory
   * @param {string} categoryId
   * @return {undefined}
   */
  function selectCategory(categoryId) {
    setState({
      ...state,
      selectedCategory: categoryId,
    });
  }

  /**
   * Toggle Private/Public
   * @function togglePrivate
   * @param {object} event object
   * @param {object} data object
   * @return {undefined}
   */
  function togglePublished(event, data) {
    setState({
      ...state,
      reviewState: state.reviewState === null ? 'published' : null,
    });
  }

  /**
   * On change filter
   * @function onChangeTextFilter
   * @param {object} event Event object.
   * @param {string} value Filter value.
   * @returns {undefined}
   */
  function onChangeTextFilter(event, { value }) {
    setState({
      ...state,
      filter: value,
    });
    if (value.length >= 3) {
      // debouncedFetchArticles();
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        fetchArticles(true, 'articlesList');
      }, 400);
    }
  }

  function onChangeSearchArticles(event, { value }) {
    setState({
      ...state,
      filter: value,
    });
  }

  function onSubmitSearchArticles(event) {
    // fetchArticles(true, 'articlesList');
    setState({
      ...state,
      filteringBy: state.filter,
    });
    event.preventDefault();
  }

  function resetFilter(event) {
    setState({
      ...state,
      filter: '',
      filteringBy: '',
    });
    // fetchArticles(true, 'articlesList');
    event.preventDefault();
  }

  return (
    <div id="page-contents">
      <Menu stackable className="top-menu prices-menu">
        <Menu.Item>
          <ArticleCategoriesFilter
            categories={categories}
            selectCategory={selectCategory}
          />
        </Menu.Item>
        <Menu.Item>
          <div>
          <p className="help">{intl.formatMessage(messages.helpSeeAllProducts)}</p>
          <div>
            <Checkbox
              toggle
              label={intl.formatMessage(messages.seeAllProducts)}
              checked={state.reviewState == null}
              onChange={(event, data) => togglePublished(event, data)}
            />
          </div>
          </div>
        </Menu.Item>
        <Menu.Item position="right" className="top-menu-searchbox">
          <Form action="/searchArticles" onSubmit={onSubmitSearchArticles}>
            <Form.Field className="searchbox">
              <Input
                type="text"
                aria-label={intl.formatMessage(messages.searchArticles)}
                onChange={onChangeSearchArticles}
                name="SearchableText"
                value={state.filter}
                transparent
                autoComplete="off"
                placeholder={intl.formatMessage(messages.searchArticles)}
                title={intl.formatMessage(messages.searchArticles)}
              />
              <button aria-label={intl.formatMessage(messages.searchArticles)}>
                <Icon name={zoomSVG} size="18px" />
              </button>
            </Form.Field>
          </Form>
        </Menu.Item>
      </Menu>
      <Dimmer.Dimmable as="div" blurring dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader indeterminate size="massive">
            <FormattedMessage id="Loading" defaultMessage="Loading" />
          </Loader>
        </Dimmer>
        <Container id="content-core">
          {state.filteringBy && (
            <div>
              <h3>
                <FormattedMessage id="filtering" defaultMessage="Filtered by" />
                &nbsp; &quot;{state.filteringBy}&quot;
                <button
                  className="filter-button ui mini circular orange icon button"
                  onClick={resetFilter}
                >
                  <i aria-hidden="true" className="close icon"></i>
                </button>
              </h3>
            </div>
          )}
          {articles?.items &&
            articles.items.map((item) => (
              <Segment key={item['@id']} className="items-wrapper">
                <div className="item-name">
                  <h4>{item.title}</h4>
                </div>
                <div className="item-image">
                  {item.image && (
                    <Image
                      src={flattenToAppURL(item.image.scales.thumb.download)}
                      size="small"
                    />
                  )}
                </div>
                {item.options &&
                  item.options.items.map((groupOption) => (
                    <GroupOptionPricesWidget
                      key={groupOption['@id']}
                      id={groupOption['@id']}
                      groupOption={groupOption}
                      onBlur={(id, value) =>
                        changePrice(id, value, groupOption, item)
                      }
                    />
                  ))}
              </Segment>
            ))}
        </Container>
        {articlesListResult?.batching && (
          <div className="contents-pagination">
            <Pagination
              current={state.currentPage}
              total={Math.ceil(articlesListResult.total / state.pageSize)}
              pageSize={state.pageSize}
              pageSizes={[15, 30, 50]}
              onChangePage={onChangePage}
              onChangePageSize={onChangePageSize}
            />
          </div>
        )}
      </Dimmer.Dimmable>
    </div>
  );
};

export default Prices;
