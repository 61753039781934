import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import rightArrowSVG from '@plone/volto/icons/right-key.svg';
import { Link, useLocation } from 'react-router-dom';
import { getBaseUrl } from '@plone/volto/helpers';

const MyShopsPlug = (props) => {
  const { pathname } = useLocation();
  const hasMyShops = useSelector(
    (state) => (state.my_shops.my_shops || []).length > 0,
  );

  return (
    <>
      {hasMyShops && (
        <Plug pluggable="toolbar-user-menu" id="myshops-menu">
          <li>
            <Link to={`${getBaseUrl(pathname)}/myshops`}>
              <FormattedMessage id="My Shops" defaultMessage="My Shops Hola" />
              <Icon name={rightArrowSVG} size="24px" />
            </Link>
          </li>
        </Plug>
      )}
    </>);
};

export default MyShopsPlug;
