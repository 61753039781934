/**
 * ShippingAgentsSettings component.
 * @module components/manage/ShippingAgentsSettings/ShippingAgentsSettings
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Segment, Table } from 'semantic-ui-react';
import { Icon as IconNext, Toolbar } from '@plone/volto/components';
import { getBaseUrl, Helmet } from '@plone/volto/helpers';
import backSVG from '@plone/volto/icons/back.svg';
import deleteSVG from '@plone/volto/icons/delete.svg';
import {
  deleteShippingAgentSettings,
  listShippingAgentsSettings,
} from '../../../actions/shop/shipping_agents_settings';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  shippingAgentsSettings: {
    id: 'Shipping Agents Settings',
    defaultMessage: 'Shipping Agents Settings',
  },
});

/**
 * Component to edit the annotations.
 * @function ShippingAgentsSettings
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const ShippingAgentsSettings = ({ location }) => {
  const { pathname } = location;
  const baseUrl = getBaseUrl(pathname);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isClient, setIsClient] = useState(null);
  const availableShippingAgentsSettings = useSelector((state) =>
    state.shippingAgentsSettings.entries.filter((entry) => !entry.configured),
  );
  const configuredShippingAgentsSettings = useSelector((state) =>
    state.shippingAgentsSettings.entries.filter((entry) => entry.configured),
  );
  const title = useSelector((state) => state.content.data.title);
  useEffect(() => setIsClient(true), []);
  useEffect(() => dispatch(listShippingAgentsSettings(baseUrl)), [
    baseUrl,
    dispatch,
  ]);

  function deleteShipAgSettings(name) {
    console.log('deleteShipAgSettings', name);
    dispatch(deleteShippingAgentSettings(baseUrl, name));
    dispatch(listShippingAgentsSettings(baseUrl));
  }

  return (
    <Container id="page-shipping-agents">
      <Helmet title={intl.formatMessage(messages.shippingAgentsSettings)} />
      <Segment.Group raised>
        <Segment className="primary">
          <FormattedMessage
            id="Shipping Agents Settings of {title}"
            defaultMessage="Shipping Agents Settings of {title}"
            values={{
              title: <q>{title}</q>,
            }}
          />
        </Segment>
        {availableShippingAgentsSettings.length > 0 && (
          <>
            <Segment secondary>
              <FormattedMessage
                id="You can see the available Shipping Agents below."
                defaultMessage="You can see the available Shipping Agents below."
              />
            </Segment>
            <Table celled padded striped attached>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id="Name" defaultMessage="Name" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {availableShippingAgentsSettings.map(
                  (shippingAgentSettings) => (
                    <Table.Row key={shippingAgentSettings.name}>
                      <Table.Cell>
                        <Link
                          to={`${baseUrl}shipping-agent-settings-edit?id=${shippingAgentSettings.name}`}
                        >
                          {shippingAgentSettings.title}
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ),
                )}
              </Table.Body>
            </Table>
          </>
        )}
        {configuredShippingAgentsSettings && (
          <>
            <Segment secondary>
              <FormattedMessage
                id="You can see the configured Shipping Agents below."
                defaultMessage="You can see the configured Shipping Agents below."
              />
            </Segment>
            <Table celled padded striped attached>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <FormattedMessage id="Name" defaultMessage="Name" />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {configuredShippingAgentsSettings.map(
                  (shippingAgentSettings) => (
                    <Table.Row key={shippingAgentSettings.name}>
                      <Table.Cell>
                        <Link
                          to={`${baseUrl}/shipping-agent-settings-edit?id=${shippingAgentSettings.name}`}
                        >
                          {shippingAgentSettings.title}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>
                        <Button
                          className="cancel"
                          aria-label={intl.formatMessage(messages.delete)}
                          onClick={() =>
                            deleteShipAgSettings(shippingAgentSettings.name)
                          }
                        >
                          <IconNext
                            name={deleteSVG}
                            className="circled"
                            size="10px"
                            title={intl.formatMessage(messages.delete)}
                          />
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ),
                )}
              </Table.Body>
            </Table>
          </>
        )}
      </Segment.Group>
      {isClient &&
        createPortal(
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to={`${getBaseUrl(pathname)}`} className="item">
                <IconNext
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
          />,
          document.getElementById('toolbar'),
        )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ShippingAgentsSettings.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default ShippingAgentsSettings;
