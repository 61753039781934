import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalForm } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { updateOrderComment } from '../../../actions/shop/orders';

const messages = defineMessages({
  comment: {
    id: 'Comment',
    defaultMessage: 'Comment',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
});

/**
 * OrderCommentEditModal
 * @function OrderCommentEditModal
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OrderCommentEditModal = ({
  url,
  order,
  open,
  onOk,
  onCancel,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(null);
  const loading = useSelector((state) => state.orders.update?.loading);
  const loaded = useSelector((state) => state.orders.update?.loaded);
  if (open && updating && loaded) {
    setUpdating(false);
    onOk();
  }
  function onSubmit({ comment }) {
    dispatch(updateOrderComment(url, order.id, { comment }));
    setUpdating(true);
  }

  return open ? (
    <ModalForm
      open={open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      title={intl.formatMessage(messages.comment)}
      formData={order}
      schema={{
        fieldsets: [
          {
            id: 'default',
            title: intl.formatMessage(messages.default),
            fields: ['comment'],
          },
        ],
        properties: {
          comment: {
            type: 'string',
            widget: 'textarea',
            title: intl.formatMessage(messages.comment),
          },
        },
        required: [],
      }}
    />
  ) : null;
};

OrderCommentEditModal.propTypes = {
  url: PropTypes.string.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    comment: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default OrderCommentEditModal;
