import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalForm } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { updateOrderPickupAddress } from '../../../actions/shop/orders';

const messages = defineMessages({
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  pickupAddress: {
    id: 'Pickup Address',
    defaultMessage: 'Pickup Address',
  },
});

/**
 * OrderPickupAddressEditModal
 * @function OrderPickupAddressEditModal
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OrderPickupAddressEditModal = ({
  url,
  order,
  open,
  onOk,
  onCancel,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(null);
  const loading = useSelector((state) => state.orders.update?.loading);
  const loaded = useSelector((state) => state.orders.update?.loaded);
  const pickupAddresses = useSelector((state) => state.orders.pickupAddresses);
  if (open && updating && loaded) {
    setUpdating(false);
    onOk();
  }
  function onSubmit({ pickup_address }) {
    dispatch(updateOrderPickupAddress(url, order.id, pickup_address));
    setUpdating(true);
  }

  return open ? (
    <ModalForm
      open={open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      title={intl.formatMessage(messages.pickupAddress)}
      formData={order}
      schema={{
        fieldsets: [
          {
            id: 'default',
            title: intl.formatMessage(messages.default),
            fields: ['pickup_address'],
          },
        ],
        properties: {
          pickup_address: {
            type: 'string',
            factory: 'Choice',
            choices: pickupAddresses.map((pa) => [pa.uid, pa.name]),
            required: true,
            noValueOption: false,
            title: intl.formatMessage(messages.pickupAddress),
          },
        },
        required: ['pickup_address'],
      }}
    />
  ) : null;
};

OrderPickupAddressEditModal.propTypes = {
  url: PropTypes.string.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    pickup_address: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default OrderPickupAddressEditModal;
