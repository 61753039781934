import React, { useEffect, useState } from 'react';
import './MyShops.css';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon as IconNext, Toolbar } from '@plone/volto/components';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { createPortal } from 'react-dom';
import { getUser, searchContent } from '@plone/volto/actions';
import { getBaseUrl, Helmet } from '@plone/volto/helpers';
import { Button, Container } from 'semantic-ui-react';
import backSVG from '@plone/volto/icons/back.svg';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  myShops: {
    id: 'My Shops',
    defaultMessage: 'My Shops',
  },
});

/**
 * MyShops container class.
 * @function MyShops
 * @param
 * @returns {string} Markup of the component
 */
const MyShops = (props) => {
  const {
    location: { pathname },
  } = props;
  const dispatch = useDispatch();
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : '',
  );
  const intl = useIntl();
  const history = useHistory();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => setIsClient(true), [setIsClient]);

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId));
    }
  }, [dispatch, userId]);

  const myShops = useSelector((state) => state.my_shops.my_shops || []);

  return (
    <div className="view-wrapper">
      <Helmet title={intl.formatMessage(messages.myShops)} />
      {myShops && (
        <Container className="myshops">
          <h2>
            <FormattedMessage id="My Shops:" defaultMessage="My Shops:" />
          </h2>
          <p>
            <FormattedMessage
              id="Select Shop"
              defaultMessage="Select the shop you wish to configure"
            />
          </p>
          {myShops.map((myShop) => (
            <li key={myShop['@id']}>
              <Link to={myShop['@id']}>{myShop.title}</Link>
            </li>
          ))}
        </Container>
     )}
     {isClient &&
       createPortal(
         <Toolbar
           pathname={pathname}
           hideDefaultViewButtons={false}
           inner={
             <Link to={`${getBaseUrl(pathname)}`} className="item">
               <IconNext
                 name={backSVG}
                 className="contents circled"
                 size="30px"
                 title={intl.formatMessage(messages.back)}
               />
             </Link>
           }
         />,
         document.getElementById('toolbar'),
       )}
    </div>
  );
};

export default MyShops;
