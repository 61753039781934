import {
  DatestableWidget,
  OptionsViewWidget,
  OptionsWidget,
  TimetableWidget,
} from '../components';
import { ReactTableWidget } from '@eeacms/volto-react-table-widget';

export const widgetMapping = {
  widget: {
    datestable_widget: DatestableWidget,
    options_widget: OptionsWidget,
    timetable_widget: ReactTableWidget, //TimetableWidget,
  },
  views: {
    widget: {
      options_widget: OptionsViewWidget,
    },
  },
};
