/**
 * Get shipping agents settings actions
 * @module actions/shop/shipping_agents_settings
 */

import {
  ADD_SHIPPING_AGENT_SETTINGS,
  DELETE_SHIPPING_AGENT_SETTINGS,
  LIST_SHIPPING_AGENTS_SETTINGS,
  GET_SHIPPING_AGENT_SETTINGS,
  UPDATE_SHIPPING_AGENT_SETTINGS,
} from '../../constants/ActionTypes';

/**
 * Get shipping agents settings
 * @function listShippingAgentsSettings
 * @param {string} url Content url
 * @returns {Object} get shipping agents settings action
 */
export function listShippingAgentsSettings(url) {
  return {
    type: LIST_SHIPPING_AGENTS_SETTINGS,
    request: {
      op: 'get',
      path: `${url}/@shipping-agents-settings`,
    },
  };
}

/**
 * Get shipping agent settings
 * @function getShippingAgentSettings
 * @param {string} url Content url
 * @returns {Object} get shipping agents settings action
 */
export function getShippingAgentSettings(url, id) {
  return {
    type: GET_SHIPPING_AGENT_SETTINGS,
    request: {
      op: 'get',
      path: `${url}/@shipping-agents-settings/${id}`,
    },
  };
}

/**
 * Add shipping agent settings
 * @function addShippingAgentSettings
 * @param {string} url Content url
 * @param {string} id shipping agent
 * @returns {Object} add shipping agents settings action
 */
export function addShippingAgentSettings(url, id) {
  return {
    type: ADD_SHIPPING_AGENT_SETTINGS,
    request: {
      op: 'post',
      path: `${url}/@shipping-agents-settings/${id}`,
    },
  };
}

/**
 * Update shipping agent settings
 * @function updateShippingAgentSettings
 * @param {string} url Content url
 * @param {string} id shipping agent
 * @param {Object} settings shipping agent settings data
 * @returns {Object} update shipping agents settings action
 */
export function updateShippingAgentSettings(url, id, settings) {
  return {
    type: UPDATE_SHIPPING_AGENT_SETTINGS,
    request: {
      op: 'patch',
      path: `${url}/@shipping-agents-settings/${id}`,
      data: settings,
    },
  };
}

/**
 * Delete shipping agent settings
 * @function deleteShippingAgentSettings
 * @param {string} url Content url
 * @param {string} id shipping agent
 * @returns {Object} delete shipping agents settings action
 */
export function deleteShippingAgentSettings(url, id) {
  return {
    type: DELETE_SHIPPING_AGENT_SETTINGS,
    request: {
      op: 'del',
      path: `${url}/@shipping-agents-settings/${id}`,
    },
  };
}
