import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ModalForm } from '@plone/volto/components';
import { useDispatch, useSelector } from 'react-redux';
import {
  defineMessages,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import { updateOrderBuyerInfo } from '../../../actions/shop/orders';

const messages = defineMessages({
  billing_address: {
    id: 'Address',
    defaultMessage: 'Address',
  },
  billing_city: {
    id: 'City',
    defaultMessage: 'City',
  },
  billing_country: {
    id: 'Country',
    defaultMessage: 'Country',
  },
  billing_county: {
    id: 'County',
    defaultMessage: 'County',
  },
  billing_email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  billing_name: {
    id: 'Name',
    defaultMessage: 'Name',
  },
  billing_organization: {
    id: 'Organization',
    defaultMessage: 'Organization',
  },
  billing_phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  billing_postcode: {
    id: 'Postcode',
    defaultMessage: 'Postcode',
  },
  buyerInfo: {
    id: 'Buyer Info',
    defaultMessage: 'Buyer Info',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
});

/**
 * OrderBuyerInfoEditModal
 * @function OrderBuyerInfoEditModal
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const OrderBuyerInfoEditModal = ({
  url,
  order,
  open,
  onOk,
  onCancel,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [updating, setUpdating] = useState(null);
  const loading = useSelector((state) => state.orders.update?.loading);
  const loaded = useSelector((state) => state.orders.update?.loaded);
  if (open && updating && loaded) {
    setUpdating(false);
    onOk();
  }
  function onSubmit(buyerInfo) {
    dispatch(updateOrderBuyerInfo(url, order.id, buyerInfo));
    setUpdating(true);
  }

  return open ? (
    <ModalForm
      open={open}
      onSubmit={onSubmit}
      onCancel={onCancel}
      title={intl.formatMessage(messages.buyerInfo)}
      formData={order}
      schema={{
        fieldsets: [
          {
            id: 'default',
            title: intl.formatMessage(messages.default),
            fields: [
              'billing_name',
              'billing_organization',
              'billing_email',
              'billing_phone',
              'billing_address',
              'billing_postcode',
              'billing_city',
              'billing_county',
              'billing_country',
            ],
          },
        ],
        properties: {
          billing_name: {
            type: 'string',
            title: intl.formatMessage(messages.billing_name),
          },
          billing_organization: {
            type: 'string',
            title: intl.formatMessage(messages.billing_organization),
          },
          billing_email: {
            type: 'string',
            widget: 'email',
            title: intl.formatMessage(messages.billing_email),
          },
          billing_phone: {
            type: 'string',
            title: intl.formatMessage(messages.billing_phone),
          },
          billing_address: {
            type: 'string',
            title: intl.formatMessage(messages.billing_address),
          },
          billing_postcode: {
            type: 'string',
            title: intl.formatMessage(messages.billing_postcode),
          },
          billing_city: {
            type: 'string',
            title: intl.formatMessage(messages.billing_city),
          },
          billing_county: {
            type: 'string',
            title: intl.formatMessage(messages.billing_county),
          },
          billing_country: {
            type: 'string',
            title: intl.formatMessage(messages.billing_country),
          },
        },
        required: [],
      }}
    />
  ) : null;
};

OrderBuyerInfoEditModal.propTypes = {
  url: PropTypes.string.isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    billing_name: PropTypes.string,
    billing_organization: PropTypes.string,
    billing_email: PropTypes.string,
    billing_phone: PropTypes.string,
    billing_address: PropTypes.string,
    billing_postcode: PropTypes.string,
    billing_city: PropTypes.string,
    billing_county: PropTypes.string,
    billing_country: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default OrderBuyerInfoEditModal;
