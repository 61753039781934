/**
 * Get shop actions
 * @module actions/shop/articles
 */

import {
  POST_ACTIVATE_ARTICLE,
  POST_DEACTIVATE_ARTICLE,
  PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE,
} from '../../constants/ActionTypes';

/**
 * Activate article
 * @function activateArticle
 * @param {string} url Content url
 * @returns {Object} Activate article action
 */
export function activateArticle(url) {
  return {
    type: POST_ACTIVATE_ARTICLE,
    request: {
      op: 'post',
      path: `${url}/@activate_article`,
    },
  };
}

/**
 * Deactivate article
 * @function deactivateArticle
 * @param {string} url Content url
 * @returns {Object} Deactivate article action
 */
export function deactivateArticle(url) {
  return {
    type: POST_DEACTIVATE_ARTICLE,
    request: {
      op: 'post',
      path: `${url}/@deactivate_article`,
    },
  };
}


/**
 * Disable until next day article
 * @function disableUntilNextDayArticle
 * @param {string} url Content url
 * @returns {Object} Disable until next day article action
 */
export function disableUntilNextDayArticle(url, data) {
  return {
    type: PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE,
    request: {
      op: 'patch',
      path: `${url}/@disable_until_next_day_article`,
      data,
    },
  };
}
