import { nonContentRoutes } from './config/NonContentRoutes';
import {
  ArticleContainerView,
  MyShops,
  MyShopsPlug,
  OrderView,
  OrdersView,
  ShippingAgentSettingsEdit,
  ShippingAgentsSettings,
  ShopView,
} from './components';
import { RedirectToMyShops } from './components';
import { widgetMapping } from './config/Widgets';
import reducers from './reducers';

const applyConfig = (config) => {
  config.settings.additionalToolbarComponents = {
    ...config.settings.additionalToolbarComponents,
    myshopsMenu: {
      component: MyShopsPlug,
      wrapper: null,
    },
  };
  config.settings.isMultiLingual = false;
  config.settings.defaultLanguage = 'es';
  config.settings.supportedLanguages = ['en', 'es', 'ca', 'eu'];  //, 'gl'];
  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    ...nonContentRoutes,
  ];
  config.settings.appExtras = [
      ...config.settings.appExtras,
      {
        match: '',
        component: RedirectToMyShops,
      },
    ];
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
      'apanymantel.cart.shopping.Shop': ShopView,
      'apanymantel.cart.shopping.ArticleContainer': ArticleContainerView,
    },
    layoutViews: {
      ...config.views.layoutViews,
    },
  };
  config.widgets = {
    ...config.widgets,
    widget: {
      ...config.widgets.widget,
      ...widgetMapping.widget,
    },
    views: {
      ...config.widgets.views,
      widget: {
        ...config.widgets.views.widget,
        ...widgetMapping.views.widget,
      },
    },
  };
  config.addonReducers = {
    ...config.addonReducers,
    ...reducers,
  }
  config.addonRoutes = [
    ...config.addonRoutes,
    {
      path: '/myshops',
      exact: true,
      component: MyShops,
    },
    {
      path: '/order',
      component: OrderView,
    },
    {
      path: '/**/order',
      component: OrderView,
    },
    {
      path: '/orders',
      component: OrdersView,
    },
    {
      path: '/**/orders',
      component: OrdersView,
    },
    {
      path: '/**/shipping-agents-settings',
      component: ShippingAgentsSettings,
    },
    {
      path: '/**/shipping-agent-settings-edit',
      component: ShippingAgentSettingsEdit,
    },
  ];

  return config;
};

export default applyConfig;
