/**
 * Orders reducer
 * @module reducers/shop/orders
 */

import {
  GET_ORDER,
  LIST_ORDERS,
  LIST_ORDER_STATES,
  UPDATE_ORDER_COMMENT,
  UPDATE_ORDER_PICKUP_ADDRESS,
  UPDATE_ORDER_SHIPPING_ADDRESS,
  UPDATE_ORDER_BUYER_INFO,
  DELETE_ORDER_ITEM,
  UPDATE_ORDER_ITEM,
} from '../../constants/ActionTypes';

const initialState = {
  get: {
    loaded: false,
    loading: false,
    error: null,
  },
  delete: {
    loaded: false,
    laoding: false,
    error: null,
  },
  list: {
    loaded: false,
    loading: false,
    error: null,
  },
  update: {
    loaded: false,
    loading: false,
    error: null,
  },
  total: 0,
  batching: {},
  items: [],
  orderStates: null,
  order: null,
  permissionToEdit: false,
  pickupAddresses: null,
  shopUrl: null,
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type
 * @returns {string} Request key
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Orders reducer
 * @function orders
 * @param {Object} state Current state
 * @param {Object} action Action to be handled
 */
export default function orders(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_ORDER}_PENDING`:
    case `${LIST_ORDERS}_PENDING`:
    case `${LIST_ORDER_STATES}_PENDING`:
    case `${UPDATE_ORDER_COMMENT}_PENDING`:
    case `${UPDATE_ORDER_PICKUP_ADDRESS}_PENDING`:
    case `${UPDATE_ORDER_SHIPPING_ADDRESS}_PENDING`:
    case `${UPDATE_ORDER_BUYER_INFO}_PENDING`:
    case `${DELETE_ORDER_ITEM}_PENDING`:
    case `${UPDATE_ORDER_ITEM}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_ORDER}_SUCCESS`:
      return {
        ...state,
        order: action.result.order,
        permissionToEdit: action.result.permission_to_edit,
        pickupAddresses: action.result.pickup_addresses,
        shopUrl: action.result.shop_url,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${LIST_ORDERS}_SUCCESS`:
      return {
        ...state,
        items: action.result.items,
        total: action.result.items_total,
        batching: action.result.batching,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_ORDER}_FAIL`:
      return {
        ...state,
        order: null,
        permissionToEdit: false,
        pickupAddresses: null,
        shopUrl: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${LIST_ORDERS}_FAIL`:
      return {
        ...state,
        items: [],
        total: 0,
        batching: {},
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${LIST_ORDER_STATES}_SUCCESS`:
      return {
        ...state,
        orderStates: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${LIST_ORDER_STATES}_FAIL`:
      return {
        ...state,
        orderStates: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${UPDATE_ORDER_COMMENT}_SUCCESS`:
    case `${UPDATE_ORDER_PICKUP_ADDRESS}_SUCCESS`:
    case `${UPDATE_ORDER_SHIPPING_ADDRESS}_SUCCESS`:
    case `${UPDATE_ORDER_BUYER_INFO}_SUCCESS`:
    case `${DELETE_ORDER_ITEM}_SUCCESS`:
    case `${UPDATE_ORDER_ITEM}_SUCCESS`:
      return {
        ...state,
        order: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${UPDATE_ORDER_COMMENT}_FAIL`:
    case `${UPDATE_ORDER_PICKUP_ADDRESS}_FAIL`:
    case `${UPDATE_ORDER_SHIPPING_ADDRESS}_FAIL`:
    case `${UPDATE_ORDER_BUYER_INFO}_FAIL`:
    case `${DELETE_ORDER_ITEM}_FAIL`:
    case `${UPDATE_ORDER_ITEM}_FAIL`:
      return {
        ...state,
        order: null,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
