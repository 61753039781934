/**
 * Orders actions
 * @module actions/shop/orders
 */

import {
  GET_ORDER,
  LIST_ORDERS,
  LIST_ORDER_STATES,
  UPDATE_ORDER_COMMENT,
  UPDATE_ORDER_PICKUP_ADDRESS,
  UPDATE_ORDER_SHIPPING_ADDRESS,
  UPDATE_ORDER_BUYER_INFO,
  DELETE_ORDER_ITEM,
  UPDATE_ORDER_ITEM,
} from '../../constants/ActionTypes';

/**
 * Get orders
 * @function listOrders
 * @param {string} url Content url
 * @param {string} b_start Batch start
 * @param {string} b_size Batch size
 * @param {object} filter
 * @returns {Object} list orders action
 */
export function listOrders(url, b_start, b_size, filter) {
  const filterParts = [];
  for (let prop in filter) {
    if (filter[prop]) {
      filterParts.push(`filter.${prop}=${filter[prop]}`);
    }
  }

  const filterString =
    filterParts.length > 0 ? '&' + filterParts.join('&') : '';

  return {
    type: LIST_ORDERS,
    request: {
      op: 'get',
      path: `${url}/@orders?b_start=${b_start}&b_size=${b_size}${filterString}`,
    },
  };
}

/**
 * Get order
 * @function getOrder
 * @param {string} url Content url
 * @param {string} id Order Id.
 * @returns {Object} get order action
 */
export function getOrder(url, id) {
  return {
    type: GET_ORDER,
    request: {
      op: 'get',
      path: `${url}/@order?id=${id}`,
    },
  };
}

/**
 * List order states
 * @function listOrderStates
 * @param {string} url Content url
 * @returns {Object} get order states
 */
export function listOrderStates(url) {
  return {
    type: LIST_ORDER_STATES,
    request: {
      op: 'get',
      path: `${url}/@order-states`,
    },
  };
}

/**
 * Update order comment
 * @function updateOrderComment
 * @param {string} url Content url.
 * @param {string} orderId Order Id.
 * @param {Object} comment data.
 * @returns {Object} Update order action.
 */
export function updateOrderComment(url, orderId, comment) {
  return {
    type: UPDATE_ORDER_COMMENT,
    request: {
      op: 'patch',
      path: `${url}/@order-comment/${orderId}`,
      data: comment,
    },
  };
}

/**
 * Update order pickup address
 * @function updateOrderPickupAddress
 * @param {string} url Content url.
 * @param {string} orderId Order Id.
 * @param {Object} comment data.
 * @returns {Object} Update order action.
 */
export function updateOrderPickupAddress(url, orderId, pickupAddress) {
  return {
    type: UPDATE_ORDER_PICKUP_ADDRESS,
    request: {
      op: 'patch',
      path: `${url}/@order-pickup-address/${orderId}`,
      data: pickupAddress,
    },
  };
}

/**
 * Update order shipping address
 * @function updateOrderShippingAddress
 * @param {string} url Content url.
 * @param {string} orderId Order Id.
 * @param {Object} shipping address data.
 * @returns {Object} Update order action.
 */
export function updateOrderShippingAddress(url, orderId, shippingAddress) {
  return {
    type: UPDATE_ORDER_SHIPPING_ADDRESS,
    request: {
      op: 'patch',
      path: `${url}/@order-shipping-address/${orderId}`,
      data: shippingAddress,
    },
  };
}

/**
 * Update order buyer info
 * @function updateOrderBuyerInfo
 * @param {string} url Content url.
 * @param {string} orderId Order Id.
 * @param {Object} buyer info data.
 * @returns {Object} Update order action.
 */
export function updateOrderBuyerInfo(url, orderId, buyerInfo) {
  return {
    type: UPDATE_ORDER_BUYER_INFO,
    request: {
      op: 'patch',
      path: `${url}/@order-buyer-info/${orderId}`,
      data: buyerInfo,
    },
  };
}

/**
 * Delete order item
 * @function deleteOrderItem
 * @param {string} url Content url.
 * @param {string} orderId Order Id.
 * @param {Object} item info data.
 * @returns {Object} Delete order action.
 */
export function deleteOrderItem(url, orderId, item) {
  return {
    type: DELETE_ORDER_ITEM,
    request: {
      op: 'del',
      path: `${url}/@order-item/${orderId}/${item._id}/${item.item}`,
    },
  };
}

/**
 * Update order item
 * @function updateOrderItem
 * @param {string} url Content url.
 * @param {string} orderId Order Id.
 * @param {Object} item.
 * @param {Object} buyer info data.
 * @returns {Object} Update order action.
 */
export function updateOrderItem(url, orderId, item) {
  return {
    type: UPDATE_ORDER_ITEM,
    request: {
      op: 'patch',
      path: `${url}/@order-item/${orderId}`,
      data: item,
    },
  };
}
