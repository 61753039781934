/**
 * ShopClosedDatesCalendar view component.
 * @module components/theme/View/ShopClosedCalendar
 */

import React, { useEffect } from 'react';
import './ShopClosedDatesCalendar.css';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { getBaseUrl } from '@plone/volto/helpers';
import MonthCalendar from './MonthCalendar';
import moment from 'moment';
import { listClosedDates } from '../../../actions/shop/closed_dates';

const messages = defineMessages({
  calendarInstructions: {
    id: 'Calendar Instructions',
    defaultMessage: 'Click on the date you want to open or close',
  },
  closedDatesCanOpen: {
    id: 'Closed Dates can Open',
    defaultMessage: 'Closed that can be opened',
  },
  closedDatesCanNotOpen: {
    id: 'Closed Dates can not Open',
    defaultMessage: 'Closed can NOT be opened',
  },
  open: {
    id: 'Open',
    defaultMessage: 'Open',
  },
});

/**
 * ShopClosedDatesCalendar view component class.
 * @function ShopClosedDatesCalendar
 * @params {object} content Content object.
 * @return {string} Markup of the component .
 */
const ShopClosedDatesCalendar = ({ pathname, monthsQty = 4 }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  moment.locale(intl.locale);

  const currentDate = moment();
  const months = Array.from({ length: monthsQty }, (item, i) => {
    const nextMonth = currentDate.month() + i;
    return moment([
      currentDate.year() + parseInt(nextMonth / 12),
      nextMonth % 12,
    ]);
  });

  useEffect(() => {
    dispatch(listClosedDates(getBaseUrl(pathname), monthsQty));
  }, [dispatch, pathname, monthsQty]);

  return (
    <div>
      <h2 className="calendar-instructions">
        <FormattedMessage
          id="Calendar Instructions"
          defaultMessage="Click on the date you want to open or close"
        />
      </h2>
      <List>
        <List.Item>
          <List.Icon name="square full" className="tomato" />
          <List.Content>
               <FormattedMessage
              id="Closed Dates can Open"
              defaultMessage="Closed that can be opened"
            />
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="square full" className="indianred" />
          <List.Content>
            <FormattedMessage
              id="Closed Dates can not Open"
              defaultMessage="Closed can NOT be opened"
            />
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="square full" className="lightgreen" />
          <List.Content>
            <FormattedMessage id="Open" defaultMessage="Open" />
          </List.Content>
        </List.Item>
      </List>
      {months.map((month) => (
        <MonthCalendar key={month} month={month} pathname={pathname} />
      ))}
    </div>
  );
};

ShopClosedDatesCalendar.propTypes = {
  pathname: PropTypes.string.isRequired,
  monthsQty: PropTypes.number,
};

export default ShopClosedDatesCalendar;
