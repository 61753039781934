/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';

import closed_dates from './shop/closed_dates';
import article_categories from './shop/article_categories';
import activate_article from './shop/activate_article';
import my_shops from './shop/my_shops';
import orders from './shop/orders';
import shippingAgentsSettings from './shop/shipping_agents_settings';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  activate_article,
  article_categories,
  closed_dates,
  my_shops,
  orders,
  shippingAgentsSettings,
};

export default reducers;
