import React, { useState, useEffect } from 'react';
import './Articles.css';
import PropTypes from 'prop-types';
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage,
  useIntl,
} from 'react-intl';
import {
  Button,
  Checkbox,
  Confirm,
  Dimmer,
  Image,
  Input,
  Loader,
  Menu,
} from 'semantic-ui-react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import { useDispatch, useSelector } from 'react-redux';
// import config from '@plone/volto/registry';
import { Pagination } from '@plone/volto/components';
import moment from 'moment';
import { updateContent } from '@plone/volto/actions';
import ArticleCategoriesFilter from './ArticleCategoriesFilter';
import { getArticleCategories } from '../../../actions/shop/categories';
import {
  activateArticle,
  deactivateArticle,
  disableUntilNextDayArticle,
} from '../../../actions/shop/articles';

const messages = defineMessages({
  all: {
    id: 'All',
    defaultMessage: 'All',
  },
  activateDeactivateIndefinite: {
    id: 'Enable Disable indefinitely',
    defaultMessage: 'Disable / Enable indefinitely',
  },
  activateProductImmediately: {
    id: 'Enable product immediately',
    defaultMessage: 'Enable product immediately',
  },
  deactivateProductImmediately: {
    id: 'Disable product',
    defaultMessage: 'Disable product',
  },
  deactivateProductUntilTomorrow: {
    id: 'Disable product until TOMORROW',
    defaultMessage: 'Disable product until TOMORROW',
  },
  deactivateUntilTomorrow: {
    id: 'Disable until tomorrow',
    defaultMessage: 'Disable until tomorrow',
  },
  filter: {
    id: 'Search product...',
    defaultMessage: 'Search product...',
  },
  loading: {
    id: 'Loading',
    defaultMessage: 'Loading',
  },
  togglePrivate: {
    id: 'Toggle private',
    defaultMessage: 'See all products / See only unpublished products',
  },
});

const Articles = ({ data, pathname }) => {
  // const { settings } = config;
  // const querystring = data.querystring || data;
  // const { b_size = settings.defaultPageSize } = querystring; // batchsize
  const articlesResult = useSelector(
    (state) => state.search.subrequests.articles,
  );
  const loading = useSelector(
    (state) => state.search.subrequests.articles?.loading,
  );
  // const articleUpdated = useSelector((state) => state.content.update.loaded);
  const articleUpdated = useSelector(
    (state) => state.activate_article.patch.loaded,
  );
  const articleActivated = useSelector(
    (state) => state.activate_article.post.loaded,
  );
  const categories = useSelector(
    (state) => state.article_categories.article_categories,
  );

  const intl = useIntl();
  moment.locale(intl.locale);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    currentPage: 0,
    pageSize: 15,
    showConfirmToggleUntilTomorrowItem: false,
    showConfirmToggleItem: false,
    toggleItemId: null,
    toggleItemAction: null,
    lastItemToggled: null,
    selectedCategory: null,
    filter: '',
    reviewState: null,
  });
  let filterTimeout = null;

  function onChangePage(event, { value }) {
    setState({
      ...state,
      currentPage: value,
    });
  }

  function onChangePageSize(event, { value }) {
    setState({
      ...state,
      pageSize: value,
      currentPage: 0,
    });
  }

  function fetchArticles() {
    let query = {
      portal_type: ['apanymantel.cart.shopping.Article'],
      'path.depth': 2, // only second level
      fullobjects: true,
      b_size: state.pageSize,
      b_start: state.pageSize * state.currentPage,
      ...(state.filter && { SearchableText: `${state.filter}*` }),
    };
    if (state.selectedCategory) {
      query.category = state.selectedCategory;
    }
    if (state.reviewState) {
      query.review_state = state.reviewState;
    }

    dispatch(searchContent(pathname, query, 'articles'));
  }

  //const debouncedFetchArticles = useDebounced(fetchArticles, 400);

  useEffect(() => {
    fetchArticles();
  }, [
    articleActivated,
    articleUpdated,
    dispatch,
    //fetchArticles,
    //intl,
    pathname,
    state.pageSize,
    state.currentPage,
    state.selectedCategory,
    // state.filter,
    state.reviewState,
  ]);

  useEffect(() => {
    dispatch(getArticleCategories(pathname));
  }, [dispatch, pathname]);

  /**
   * Confirm activate
   * @function confirmActivate
   * @param {object} event object
   * @param {object} data object
   * @param {string} item id
   */
  function confirmActivate(event, data, itemId) {
    setState({
      ...state,
      showConfirmToggleItem: true,
      toggleItemId: itemId,
      toggleItemAction: data.checked ? 'activate' : 'deactivate',
    });
  }

  /**
   * Confirm Deactivate until tomorrow
   * @function confirmDeactiveUntilTomorrow
   * @param {object} event object
   * @param {object} data object
   * @param {string} item id
   * @return {undefined}
   */
  function confirmDeactivateUntilTomorrow(event, data, itemId) {
    setState({
      ...state,
      showConfirmToggleUntilTomorrowItem: true,
      toggleItemId: itemId,
      toggleItemAction: data.checked ? 'deactivate' : 'activate',
    });
  }

  /**
   * Toggle Item Cancel
   * @function toggleItemCancel
   * @return {undefined}
   */
  function toggleItemCancel() {
    setState({
      ...state,
      showConfirmToggleItem: false,
      showConfirmToggleUntilTomorrowItem: false,
      toggleItemId: null,
      toggleItemAction: null,
    });
  }

  /**
   * Toggle Item until tommorrow
   * @function toggleItemUntilTomorrow
   * @return {undefined}
   */
  function toggleItemUntilTomorrowOK() {
    dispatch(
      disableUntilNextDayArticle(flattenToAppURL(state.toggleItemId), {
        disable_until_next_day: state.toggleItemAction === 'deactivate',
      }),
    );
    // dispatch(
    //   updateContent(flattenToAppURL(state.toggleItemId), {
    //     disable_until_next_day: state.toggleItemAction === 'deactivate',
    //   }),
    // );
    setState({
      ...state,
      lastItemToggled: {
        id: state.toggleItemId,
        action: state.toggleItemAction,
      },
      showConfirmToggleUntilTomorrowItem: false,
      toggleItemId: null,
      toggleItemAction: null,
    });
  }

  /**
   * Toggle Item
   * @function toggleItemOK
   * @return {undefined}
   */
  function toggleItemOK() {
    if (state.toggleItemAction === 'deactivate') {
      dispatch(deactivateArticle(flattenToAppURL(state.toggleItemId)));
    } else {
      dispatch(activateArticle(flattenToAppURL(state.toggleItemId)));
    }
    setState({
      ...state,
      showConfirmToggleItem: false,
      toggleItemId: null,
      toggleItemAction: null,
    });
  }

  /**
   * Toggle Private/Public
   * @function togglePrivate
   * @param {object} event object
   * @param {object} data object
   * @return {undefined}
   */
  function togglePrivate(event, data) {
    setState({
      ...state,
      reviewState: state.reviewState === null ? 'private' : null,
    });
  }

  /**
   * Select Category
   * @function selectCategory
   * @param {string} category Id
   * @return {undefined}
   */
  function selectCategory(categoryId) {
    setState({
      ...state,
      selectedCategory: categoryId,
    });
  }

  /**
   * On change filter
   * @function onChangeTextFilter
   * @param {object} event Event object.
   * @param {string} value Filter value.
   * @returns {undefined}
   */
  function onChangeTextFilter(event, { value }) {
    setState({
      ...state,
      filter: value,
    });
    if (value.length >= 3) {
      // debouncedFetchArticles();
      clearTimeout(filterTimeout);
      filterTimeout = setTimeout(() => {
        fetchArticles();
      }, 400);
    }
  }

  /**
   * Item is published
   * @function itemIsPublished
   * @param {object} item Item object.
   * @returns {bool}
   */
  function itemIsPublished(item) {
    const now = moment();
    return (
      item.review_state === 'published' &&
      (!item.effective || now.isSameOrAfter(moment.utc(item.effective))) &&
      (!item.expires || now.isSameOrBefore(moment.utc(item.expires)))
    );
  }

  /**
   * Item is private
   * @function itemIsPrivate
   * @param {object} item Item object.
   * @returns {bool}
   */
  function itemIsPrivate(item) {
    return item.review_state === 'private';
  }

  /**
   * Item expired
   * @function itemExpired
   * @param {object} item Item object.
   * @returns {bool}
   */
  function itemExpired(item) {
    const now = moment();
    return item.expires && now.isAfter(moment.utc(item.expires));
  }

  /**
   * Item upcoming
   * @function itemUpcoming
   * @param {object} item Item object.
   * @returns {bool}
   */
  function itemUpcoming(item) {
    const now = moment();
    return (
      item.effective &&
      !item.disable_until_next_day &&
      now.isBefore(moment.utc(item.effective))
    );
  }

  return (
    <div id="page-contents">
      <>
        <Confirm
          open={state.showConfirmToggleUntilTomorrowItem}
          header={intl.formatMessage(
            state.toggleItemAction === 'deactivate'
              ? messages.deactivateProductUntilTomorrow
              : messages.activateProductImmediately,
          )}
          content={
            <div className="content">
              <FormattedMessage
                id="areYouSure"
                defaultMessage="Are you sure?"
              />
            </div>
          }
          onCancel={toggleItemCancel}
          onConfirm={toggleItemUntilTomorrowOK}
          size="tiny"
        />
        <Confirm
          open={state.showConfirmToggleItem}
          header={intl.formatMessage(
            state.toggleItemAction === 'deactivate'
              ? messages.deactivateProductImmediately
              : messages.activateProductImmediately,
          )}
          content={
            <div className="content">
              <FormattedMessage
                id="areYouSure"
                defaultMessage="Are you sure?"
              />
            </div>
          }
          onCancel={toggleItemCancel}
          onConfirm={toggleItemOK}
          size="tiny"
        />
        <Menu stackable className="top-menu">
          <Menu.Item>
            <ArticleCategoriesFilter
              categories={categories}
              selectCategory={selectCategory}
            />
          </Menu.Item>
          <Menu.Item>
            <Checkbox
              toggle
              label={intl.formatMessage(messages.togglePrivate)}
              checked={state.reviewState !== null}
              onChange={(event, data) => togglePrivate(event, data)}
            />
          </Menu.Item>
          <Menu.Item position="right" className="top-menu-searchbox">
            <div className="ui icon input">
              <Input
                type="text"
                placeholder={intl.formatMessage(messages.filter)}
                value={state.filter}
                onChange={onChangeTextFilter}
              />
              <i aria-hidden="true" className="search icon"></i>
              <div className="results" />
            </div>
          </Menu.Item>
        </Menu>
        <Dimmer.Dimmable as="div" blurring dimmed={loading}>
          <Dimmer active={loading} inverted>
            <Loader indeterminate size="massive">
              <FormattedMessage id="Loading" defaultMessage="Loading" />
            </Loader>
          </Dimmer>
          <div className="ui cards">
            {articlesResult?.items &&
              articlesResult.items.map((item) => (
                <div
                  className={cx('ui centered card', {
                    published: itemIsPublished(item),
                    private: itemIsPrivate(item),
                    expired: itemExpired(item),
                    upcoming: itemUpcoming(item),
                  })}
                  padded="true"
                  key={item.UID}
                >
                  <div className="image">
                    {item.image && (
                      <Image
                        src={flattenToAppURL(
                          item.image.scales.preview.download,
                        )}
                        size="small"
                        floated="right"
                      />
                    )}
                  </div>
                  <div className="content">
                    <h2 className="header">{item.title}</h2>
                  </div>
                  <div className="extra content">
                    <Checkbox
                      toggle
                      label={intl.formatMessage(
                        messages.activateDeactivateIndefinite,
                      )}
                      disabled={itemExpired(item) || itemUpcoming(item)}
                      checked={item.review_state === 'published'}
                      onChange={(event, data) =>
                        confirmActivate(event, data, item['@id'])
                      }
                    />
                  </div>
                  <div className="extra content">
                    <Checkbox
                      toggle
                      label={intl.formatMessage(
                        messages.deactivateUntilTomorrow,
                      )}
                      disabled={itemExpired(item) || itemUpcoming(item)}
                      checked={item.disable_until_next_day || false}
                      onChange={(event, data) =>
                        confirmDeactivateUntilTomorrow(event, data, item['@id'])
                      }
                    />
                  </div>
                  <div className="extra content publish-dates">
                    {itemExpired(item) ? (
                      <h4 className="product-expired-banner">
                        <FormattedMessage
                          id="thisProductExpired"
                          defaultMessage="Product Expired due to old publishing dates"
                        />
                      </h4>
                    ) : (
                      item.availability_dates !== null &&
                      item.availability_dates.length !== 0 && (
                        <div>
                          <h5>
                            <FormattedMessage
                              id="activatesOnDates"
                              defaultMessage="Product activates only on specific Dates"
                            />
                          </h5>
                          {item.availability_dates.map((daterange, idx) => (
                            <div key={idx}>
                              <div className="date-range">
                                {daterange.from_date === daterange.to_date ? (
                                  <FormattedHTMLMessage
                                    id="activatedOn"
                                    defaultMessage="On <b>{fromDate}</b>"
                                    values={{
                                      fromDate: moment(
                                        daterange.from_date,
                                      ).format('D MMM'),
                                    }}
                                  />
                                ) : (
                                  <FormattedHTMLMessage
                                    id="activatedFrom"
                                    defaultMessage="Between <b>{fromDate}</b> and <b>{toDate}</b>."
                                    values={{
                                      fromDate: moment(
                                        daterange.from_date,
                                      ).format('D MMM'),
                                      toDate: moment(
                                        daterange.to_date,
                                      ).format('D MMM'),
                                    }}
                                  />
                                )}
                                {daterange.repeat_every_year && (
                                  <FormattedMessage
                                    id="repeatedAnnually"
                                    defaultMessage="It repeats every year."
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
          </div>
          {articlesResult?.batching && (
            <div className="contents-pagination">
              <Pagination
                current={state.currentPage}
                total={Math.ceil(articlesResult.total / state.pageSize)}
                pageSize={state.pageSize}
                pageSizes={[15, 30, 50]}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
              />
            </div>
          )}
        </Dimmer.Dimmable>
      </>
    </div>
  );
};

/**
 * Property Types
 * @property {Object} propTypes Property types.
 * @static
 */
Articles.propTypes = {
  data: PropTypes.shape({
    querystring: PropTypes.string,
  }).isRequired,
  pathname: PropTypes.string.isRequired,
};

export default Articles;
