/**
 * Shipping Agents Settings reducer
 * @module reducers/shop/shipping_agents_settings
 */

import {
  GET_SHIPPING_AGENT_SETTINGS,
  LIST_SHIPPING_AGENTS_SETTINGS,
} from '../../constants/ActionTypes';

const initialState = {
  get: {
    loaded: false,
    loading: false,
    error: null,
  },
  entries: [],
  shippingAgentSettings: {},
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type
 * @returns {string} Request key
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

/**
 * Shipping Agents Settings reducer
 * @function shippingAgentsSettings
 * @param {Object} state Current state
 * @param {Object} action Action to be handled
 */
export default function shippingAgentsSettings(
  state = initialState,
  action = {},
) {
  switch (action.type) {
    case `${GET_SHIPPING_AGENT_SETTINGS}_PENDING`:
    case `${LIST_SHIPPING_AGENTS_SETTINGS}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${GET_SHIPPING_AGENT_SETTINGS}_SUCCESS`:
      return {
        ...state,
        shippingAgentSettings: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${LIST_SHIPPING_AGENTS_SETTINGS}_SUCCESS`:
      return {
        ...state,
        entries: action.result,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${GET_SHIPPING_AGENT_SETTINGS}_FAIL`:
      return {
        ...state,
        shippingAgentSettings: {},
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${LIST_SHIPPING_AGENTS_SETTINGS}_FAIL`:
      return {
        ...state,
        entries: [],
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}
