/**
 * Annotation view component.
 * @module components/theme/View/AnnotationView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * Component to edit the annotations.
 * @function AnnotationView
 * @param {Object} content Content object.
 * @returns {string} Markup of the component.
 */
const AnnotationView = ({ content, intl, location }) => {
  console.log('AnnotationView');
  return (
    <div>
      Hola
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AnnotationView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default injectIntl(AnnotationView);
