/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */
export const ADD_SHOP_CLOSED_DATE = 'ADD_SHOP_CLOSED_DATE';
export const DELETE_SHOP_CLOSED_DATE = 'DELETE_SHOP_CLOSED_DATE';
export const LIST_SHOP_CLOSED_DATES = 'LIST_SHOP_CLOSED_DATES';

export const GET_ARTICLE_CATEGORIES = 'GET_ARTICLE_CATEGORIES';

export const POST_DEACTIVATE_ARTICLE = 'POST_DEACTIVATE_ARTICLE';
export const POST_ACTIVATE_ARTICLE = 'POST_ACTIVATE_ARTICLE';
export const PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE =
  'PATCH_DISABLE_UNTIL_NEXT_DAY_ARTICLE';

export const GET_MY_SHOPS = 'GET_MY_SHOPS';

export const ADD_SHIPPING_AGENT_SETTINGS = 'ADD_SHIPPING_AGENT_SETTINGS';
export const DELETE_SHIPPING_AGENT_SETTINGS = 'DELETE_SHIPPING_AGENT_SETTINGS';
export const GET_SHIPPING_AGENT_SETTINGS = 'GET_SHIPPING_AGENT_SETTINGS';
export const LIST_SHIPPING_AGENTS_SETTINGS = 'LIST_SHIPPING_AGENTS_SETTINGS';
export const UPDATE_SHIPPING_AGENT_SETTINGS = 'UPDATE_SHIPPING_AGENT_SETTINGS';

export const GET_ORDER = 'GET_ORDER';
export const LIST_ORDERS = 'LIST_ORDERS';
export const LIST_ORDER_STATES = 'LIST_ORDER_STATES';
export const UPDATE_ORDER_COMMENT = 'UPDATE_ORDER_COMMENT';
export const UPDATE_ORDER_PICKUP_ADDRESS = 'UPDATE_ORDER_PICKUP_ADDRESS';
export const UPDATE_ORDER_SHIPPING_ADDRESS = 'UPDATE_ORDER_SHIPPING_ADDRESS';
export const UPDATE_ORDER_BUYER_INFO = 'UPDATE_ORDER_BUYER_INFO';
export const DELETE_ORDER_ITEM = 'DELETE_ORDER_ITEM';
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM';
