/**
 * OrdersView component.
 * @module components/manage/OrdersView/OrdersView
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getVocabulary } from '@plone/volto/actions';
import {
  Button,
  Container,
  Dimmer,
  Dropdown,
  Input,
  Loader,
  Menu,
  Popup,
  Segment,
  Select,
  Table,
} from 'semantic-ui-react';
import { getBaseUrl, Helmet } from '@plone/volto/helpers';
import { createPortal } from 'react-dom';
import {
  FormattedDate,
  Icon,
  Pagination,
  SelectWidget,
  Toolbar,
} from '@plone/volto/components';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import backSVG from '@plone/volto/icons/back.svg';
import zoomSVG from '@plone/volto/icons/zoom.svg';
import moreSVG from '@plone/volto/icons/more.svg';
import editingSVG from '@plone/volto/icons/editing.svg';
import showSVG from '@plone/volto/icons/show.svg';
import filterSVG from '@plone/volto/icons/filter.svg';
import infoSVG from '@plone/volto/icons/info.svg';
import { listOrders, listOrderStates } from '../../../actions/shop/orders';
import moment from 'moment';
import './OrdersView.css';

const messages = defineMessages({
  back: {
    id: 'Back',
    defaultMessage: 'Back',
  },
  delivery: {
    id: 'Delivery',
    defaultMessage: 'Delivery',
  },
  deliverOrPickup: {
    id: 'Select Delivery/Pickup',
    defaultMessage: 'Select Delivery/Pickup',
  },
  edit: {
    id: 'Edit',
    defaultMessage: 'Edit',
  },
  endDatePlaceholderText: {
    id: 'End date',
    defaultMessage: 'End date',
  },
  filter: {
    id: 'filter',
    defaultMessage: 'filter',
  },
  filterId: {
    id: 'Filter by Id.',
    defaultMessage: 'Filter by Id.',
  },
  filterShippingName: {
    id: 'Filter by Name',
    defaultMessage: 'Filter by Name',
  },
  no_value: {
    id: 'No value',
    defaultMessage: 'No value',
  },
  orders: {
    id: 'orders',
    defaultMessage: 'Orders',
  },
  pickup: {
    id: 'Pickup',
    defaultMessage: 'Pickup',
  },
  selectOrderState: {
    id: 'Select order state',
    defaultMessage: 'Select State',
  },
  selectShop: {
    id: 'Select shop',
    defaultMessage: 'Select Shop',
  },
  startDatePlaceholderText: {
    id: 'Start date',
    defaultMessage: 'Start date',
  } ,
});

const SHOPS_VOCAB = 'kg.cart.availableshopsvocabulary';

/**                                                                                                                           
 * Component to view the orders.                                                                                              
 * @function OrdersView                                                                                                       
 * @param {Object} content Content object.                                                                                    
 * @returns {string} Markup of the component.                                                                                 
 */                                                                                                                           
const OrdersView = ({ location }) => {                                                                                        
  const { pathname } = location;                                                                                              
  const intl = useIntl();                                                                                                     
  moment.locale(intl.locale);                                                                                                 
  const [isClient, setIsClient] = useState(null);                                                                             
  const dispatch = useDispatch();                                                                                             
  const baseUrl = getBaseUrl(pathname);                                                                                       
  const orders = useSelector((state) => state.orders);                                                                        
  const loading = useSelector((state) => state.orders.loading);                                                               
  const breadcrumbs = useSelector((state) => state.breadcrumbs.items);                                                        
  const orderStates = useSelector((state) => state.orders.orderStates);                                                       
  const showShop = useSelector(                                                                                               
    (state) => state.content.data['@type'] !== 'apanymantel.cart.shopping.Shop',                                              
  );                                                                                                                          
  if (showShop) {                                                                                                             
    useEffect(() => {                                                                                                         
      dispatch(                                                                                                               
        getVocabulary({ vocabNameOrURL: SHOPS_VOCAB }),                                                                       
      );                                                                                                                      
    }, [dispatch]);                                                                                                           
    const shopsVocab = useSelector((state) => state.vocabularies[SHOPS_VOCAB]);                                               
  }                                                                                                                           
  const deliverOrPickup = [                                                                                                   
    {                                                                                                                         
      label: intl.formatMessage(messages.delivery),                                                                           
      value: 'delivery',                                                                                                      
    },                                                                                                                        
    {                                                                                                                         
      label: intl.formatMessage(messages.pickup),                                                                             
      value: 'pickup',                                                                                                        
    },                                                                                                                        
  ];                                                                                                                          
  const [state, setState] = useState({                                                                                        
    filter: {                                                                                                                 
      id: '',                                                                                                                 
      shippingName: '',                                                                                                       
      shop: null,                                                                                                             
      fromOrderDatetime: null,                                                                                                
      toOrderDatetime: null,                                                                                                  
      fromDeliveryPickupDatetime: null,                                                                                       
      toDeliveryPickupDatetime: null,                                                                                         
      state: '',                                                                                                              
    },
    focusedInputOrderDatetime: null,                                                                                          
    focusedInputDeliveryPickupDatetime: null,                                                                                 
    currentPage: 0,                                                                                                           
    pageSize: 15,                                                                                                             
    refresh: false,                                                                                                           
  });                                                                                                                         
  function onChangePage(event, { value }) {                                                                                   
    setState({                                                                                                                
      ...state,                                                                                                               
      currentPage: value,                                                                                                     
    });                                                                                                                       
  }                                                                                                                           
                                                                                                                              
  function onChangePageSize(event, { value }) {                                                                               
    setState({                                                                                                                
      ...state,                                                                                                               
      pageSize: value,                                                                                                        
      currentPage: 0,                                                                                                         
    });                                                                                                                       
  }                                                                                                                           
                                                                                                                              
  function onClickFilter(event) {                                                                                             
    setState({                                                                                                                
      ...state,                                                                                                               
      currentPage: 0,                                                                                                         
      refresh: !state.refresh,                                                                                                
    });                                                                                                                       
  }                                                                                                                           
                                                                                                                              
  function formatDate2Datetime(d, to = false) {                                                                               
    if (to) {                                                                                                                 
      return moment(d)                                                                                                        
        .hour(23)                                                                                                             
        .minute(59)                                                                                                           
        .second(59)                                                                                                           
        .millisecond(999)                                                                                                     
        .toISOString();
    } else {
      return moment(d).toISOString();
    }
  }

  function onChangeFilter(id, value) {
    const filter = state.filter;                                                                                              
    switch (id) {                                                                                                             
      case 'filterId':                                                                                                        
        filter.id = value;                                                                                                    
        break;
      case 'filterShop':
        filter.shop = value;
        break;
      case 'filterShippingName':
        filter.shippingName = value;
        break;
      case 'filterFromOrderDatetime':
        filter.fromOrderDatetime = formatDate2Datetime(value);
        break;
      case 'filterToOrderDatetime':
        filter.toOrderDatetime = formatDate2Datetime(value, true);
        break;
      case 'filterFromDeliveryPickupDatetime':
        filter.fromDeliveryPickupDatetime = formatDate2Datetime(value);
        break;
      case 'filterToDeliveryPickupDatetime':
        filter.toDeliveryPickupDatetime = formatDate2Datetime(value, true);
        break;
      case 'filterState':
        filter.state = value === 'no-value' ? '' : value;
        break;
      case 'filterDeliverOrPickup':
        filter.deliverOrPickup = value === 'no-value' ? '' : value;
        break;
    }

    setState({
      ...state,
      filter,
    });
  }

  function onChangeFilterDateRange(id, startDate, endDate) {
    const filter = state.filter;
    switch (id) {
      case 'filterOrderDatetime':
        filter.fromOrderDatetime = startDate;
        filter.toOrderDatetime = endDate;
        break;
      case 'filterDeliveryPickupDatetime':
        filter.fromDeliveryPickupDatetime = startDate;
        filter.toDeliveryPickupDatetime = endDate;
        break;
    }
    setState({
      ...state,
      filter,
    });
  }

  function getOrderStateTitle(orderStateToken) {
    if (orderStates) {                                                                                                        
      const orderState = orderStates.filter((os) => os.id === orderStateToken);                                               
      if (orderState.length > 0) {                                                                                            
        return orderState[0].title;                                                                                           
      }                                                                                                                       
    }                                                                                                                         
  }                                                                                                                           
                                                                                                                              
  function getDeliverOrPickup(delOrPic) {                                                                                     
    switch (delOrPic) {                                                                                                       
      case 'delivery':                                                                                                        
        return intl.formatMessage(messages.delivery);                                                                         
      case 'pickup':                                                                                                          
        return intl.formatMessage(messages.pickup);                                                                           
    }                                                                                                                         
  }                                                                                                                           
                                                                                                                              
  useEffect(() => dispatch(listOrderStates(baseUrl)), [dispatch, baseUrl]);                                                   
  useEffect(() => setIsClient(true), []);                                                                                     
  useEffect(() => {                                                                                                           
    dispatch(                                                                                                                 
      listOrders(baseUrl, state.currentPage * state.pageSize, state.pageSize, {                                               
        ...state.filter,                                                                                                      
        shippingName: state.filter.shippingName                                                                               
          ? `*${state.filter.shippingName}*`                                                                                  
          : undefined,                                                                                                        
        fromOrderDatetime: state.filter.fromOrderDatetime                                                                     
          ? state.filter.fromOrderDatetime                                                                                    
                 .hour(0)                                                                                                     
                 .minute(0)                                                                                                   
                 .second(0)                                                                                                   
                 .millisecond(0)                                                                                              
                 .toISOString()                                                                                               
          : undefined,                                                                                                        
        toOrderDatetime: state.filter.toOrderDatetime                                                                         
          ? state.filter.toOrderDatetime                                                                                      
                 .hour(23)
                 .minute(59)
                 .second(59)
                 .millisecond(999)
                 .toISOString()
          : undefined,
        fromDeliveryPickupDatetime: state.filter.fromDeliveryPickupDatetime
          ? state.filter.fromDeliveryPickupDatetime
                 .hour(0)
                 .minute(0)
                 .second(0)
                 .millisecond(0)
                 .toISOString()
          : undefined,
        toDeliveryPickupDatetime: state.filter.toDeliveryPickupDatetime
          ? state.filter.toDeliveryPickupDatetime                                                                             
                 .hour(23)                                                                                                    
                 .minute(59)                                                                                                  
                 .second(59)                                                                                                  
                 .millisecond(999)                                                                                            
                 .toISOString()                                                                                               
          : undefined,                                                                                                        
      }),                                                                                                                     
    );
  }, [
    dispatch,
    baseUrl,
    state.pageSize,
    state.currentPage,
    state.refresh,
  ]);

  return (
    <Container id="page-orders">
      <Helmet title={intl.formatMessage(messages.orders)} />
      {isClient &&
        createPortal(
          <Toolbar
            pathname={pathname}
            hideDefaultViewButtons
            inner={
              <Link to={`${getBaseUrl(pathname)}`} className="item">
                <Icon
                  name={backSVG}
                  className="contents circled"
                  size="30px"
                  title={intl.formatMessage(messages.back)}
                />
              </Link>
            }
           />,
          document.getElementById('toolbar'),
        )}
      <Dimmer.Dimmable as="div" blurring dimmed={loading}>
        <Dimmer active={loading} inverted>
          <Loader indeterminate size="massive">
            <FormattedMessage id="Loading" defaultMessage="Loading" />
          </Loader>
        </Dimmer>
        <article>
          <section id="content-core">
            <Segment.Group raised>
              {/*
                 <Menu secondary attached className="top-menu">                                                               
                 <Menu.Menu className="top-menu-menu">                                                                        
                 <div className="ui right aligned category search item">                                                      
                 <button                                                                                                      
                 className="item ui noborder button"                                                                          
                 aria-label={intl.formatMessage(messages.filter)}                                                             
                 onClick={onClickFilter}                                                                                      
                 >                                                                                                            
                 <Icon name={filterSVG} size="18px" />                                                                        
                 </button>                                                                                                    
                 </div>                                                                                                       
                 </Menu.Menu>                                                                                                 
               */}                                                                                                            
              {/* <Menu.Menu position="right" className="top-menu-searchbox"> */}                                             
              {/*   <div className="ui right aligned category search item"> */}                                               
              {/*     <Input */}                                                                                              
              {/*       type="text" */}                                                                                       
              {/*       transparent */}                                                                                       
              {/*       placeholder={intl.formatMessage(messages.filter)} */}                                                 
              {/*       size="small" */}                                                                                      
              {/*       value={state.filter} */}                                                                              
              {/*       onChange={onChangeFilter} */}                                                                         
              {/*     /> */}                                                                                                  
              {/*     <Icon */}                                                                                               
              {/*       name={zoomSVG} */}                                                                                    
              {/*       size="30px" */}                                                                                       
              {/*       color="#007eb1" */}                                                                                   
              {/*       className="zoom" */}                                                                                  
              {/*     /> */}                                                                                                  
              {/*     <div className="results" /> */}
              {/*   </div> */}
              {/* </Menu.Menu> */}
              {/* </Menu>*/}
              {/*<Segment
                 secondary
                 attached
                 className="contents-breadcrumbs"
                 >
                 <ContentsBreadcrumbs items={breadcrumbs} />
                 </Segment>*/}
              <div className="contents-table-wrapper">
                <Table compact singleLine attached>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <FormattedMessage id="Order number" defaultMessage="Order number" />
                      </Table.HeaderCell>
                      {showShop && (
                        <Table.HeaderCell>
                          <FormattedMessage id="Shop" defaultMessage="Shop" />
                        </Table.HeaderCell>
                      )}
                      <Table.HeaderCell>
                        <FormattedMessage id="Receiver name" defaultMessage="Receiver name" />                                
                      </Table.HeaderCell>                                                                                     
                      {/*                                                                                                     
                         <Table.HeaderCell>                                                                                   
                         <FormattedMessage                                                                                    
                         id="Order Date"                                                                                      
                         defaultMessage="Order Date"                                                                          
                         />                                                                                                   
                         </Table.HeaderCell>                                                                                  
                       */}                                                                                                    
                      <Table.HeaderCell>                                                                                      
                        <FormattedMessage id="Status" defaultMessage="Status" />                                              
                        <Popup                                                                                                
                          content={                                                                                           
                            <table className="ui definition table compact">
                              {orderStates &&
                               orderStates.map((os) => (
                                 <tr key={os.title}>
                                   <td>
                                     <h5>{os.title}</h5>
                                   </td>
                                   <td>
                                     <small>{os.description}</small>
                                   </td>
                                 </tr>
                               ))}
                            </table>
                          }
                          key={'key'}
                          trigger={
                            <a href="#State">
                              <Icon
                                circular
                                name={infoSVG}
                                size="22px"
                                className="more-info-icon"
                              />
                            </a>
                          }
                          position="bottom center"
                          wide="very"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <FormattedMessage
                          id="Deliver/Pickup"
                          defaultMessage="Deliver/Pickup"
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <FormattedMessage                                                                                     
                          id="Delivery/Pickup Date"                                                                           
                          defaultMessage="Delivery/Pickup Date"                                                               
                        />                                                                                                    
                      </Table.HeaderCell>                                                                                     
                      {/*                                                                                                     
                      <Table.HeaderCell textAlign="right">                                                                    
                        <FormattedMessage                                                                                     
                          id="Actions"                                                                                        
                          defaultMessage="Actions"                                                                            
                        />                                                                                                    
                      </Table.HeaderCell>                                                                                     
                       */}                                                                                                    
                    </Table.Row>                                                                                              
                  </Table.Header>                                                                                             
                  <Table.Body>                                                                                                
                    <Table.Row className="filters-row">                                                                       
                      <Table.Cell>                                                                                            
                        <Input                                                                                                
                          id="filterId"                                                                                       
                          type="text"                                                                                         
                          placeholder={intl.formatMessage(messages.filterId)}                                                 
                          size="small"
                          value={state.filter.id}
                          onChange={(event, { value }) =>
                            onChangeFilter(event.target.id, value)
                          }
                        />
                      </Table.Cell>
                      {showShop && (
                        <Table.Cell>
                          <SelectWidget
                            id="filterShop"
                            placeholder={intl.formatMessage(
                              messages.selectShop,
                            )}
                            intl={intl}
                            widgetOptions={({vocabulary: {'@id': SHOPS_VOCAB}})}
                            onChange={(event, obj) =>
                              onChangeFilter(obj.id, obj.value)
                            }
                          />
                        </Table.Cell>
                      )}
                      <Table.Cell>
                        <Input                                                                                                
                          id="filterShippingName"                                                                             
                          type="text"                                                                                         
                          placeholder={intl.formatMessage(                                                                    
                            messages.filterShippingName,                                                                      
                          )}                                                                                                  
                          size="small"                                                                                        
                          value={state.filter.shippingName}                                                                   
                          onChange={(event, { value }) =>                                                                     
                            onChangeFilter(event.target.id, value)                                                            
                          }                                                                                                   
                        />                                                                                                    
                      </Table.Cell>                                                                                           
                      {/*
                         <Table.Cell>
                         <DateRangePicker
                         startDate={state.filter.fromOrderDatetime}
                         startDateId="filterFromOrderDatetime"
                         endDate={state.filter.toOrderDatetime}
                         endDateId="filterToOrderDatetime"
                         startDatePlaceholderText={intl.formatMessage(
                         messages.startDatePlaceholderText,
                         )}
                         startDateAriaLabel={intl.formatMessage(
                         messages.startDatePlaceholderText,
                         )}
                         endDatePlaceholderText={intl.formatMessage(
                         messages.endDatePlaceholderText,
                         )}
                         endDateAriaLabel={intl.formatMessage(
                         messages.endDatePlaceholderText,
                         )}
                         onDatesChange={({ startDate, endDate }) =>
                         onChangeFilterDateRange(
                         'filterOrderDatetime',
                         startDate,
                         endDate,
                         )
                         }
                         focusedInput={state.focusedInputOrderDatetime}
                         onFocusChange={(focusedInputOrderDatetime) =>
                         setState({ ...state, focusedInputOrderDatetime })
                         }
                         isOutsideRange={(day) => false}
                         showClearDates={true}
                         minimumNights={0}
                         />
                         </Table.Cell>
                       */}
                      <Table.Cell>
                        <Select
                          id="filterState"                                                                                    
                          placeholder={intl.formatMessage(                                                                    
                            messages.selectOrderState,                                                                        
                          )}                                                                                                  
                          value={state.filter?.state}                                                                         
                          options={[                                                                                          
                            {                                                                                                 
                              key: 'no-value',                                                                                
                              value: 'no-value',                                                                              
                              text: intl.formatMessage(messages.no_value),                                                    
                            },                                                                                                
                            ...(orderStates                                                                                   
                                ? orderStates.map((os) => ({                                                                  
                                  key: os.id,                                                                                 
                                  value: os.id,                                                                               
                                  text: os.title,                                                                             
                                }))                                                                                           
                                : []),                                                                                        
                          ]}                                                                                                  
                          onChange={(event, obj) =>                                                                           
                            onChangeFilter(obj.id, obj.value)                                                                 
                          }                                                                                                   
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <Select
                          id="filterDeliverOrPickup"
                          placeholder={intl.formatMessage(
                            messages.deliverOrPickup,
                          )}
                          value={state.filter?.deliverOrPickup}
                          options={[
                            {
                              key: 'no-value',
                              value: 'no-value',
                              text: intl.formatMessage(messages.no_value),
                            },
                            ...(deliverOrPickup
                                ? deliverOrPickup.map((dp) => ({
                                  key: dp.value,
                                  value: dp.value,
                                  text: dp.label,
                                }))
                                : []),
                          ]}
                          onChange={(event, obj) =>
                            onChangeFilter(obj.id, obj.value)
                          }
                        />
                      </Table.Cell>
                      <Table.Cell>
                        <DateRangePicker
                          startDate={state.filter.fromDeliveryPickupDatetime}                                                 
                          startDateId="filterFromDeliveryPickupDatetime"                                                      
                          endDate={state.filter.toDeliveryPickupDatetime}                                                     
                          endDateId="filterToDeliveryPickupDatetime"                                                          
                          startDatePlaceholderText={intl.formatMessage(                                                       
                            messages.startDatePlaceholderText,                                                                
                          )}                                                                                                  
                          startDateAriaLabel={intl.formatMessage(                                                             
                            messages.startDatePlaceholderText,                                                                
                          )}                                                                                                  
                          endDatePlaceholderText={intl.formatMessage(                                                         
                            messages.endDatePlaceholderText,                                                                  
                          )}                                                                                                  
                          endDateAriaLabel={intl.formatMessage(                                                               
                            messages.endDatePlaceholderText,                                                                  
                          )}                                                                                                  
                          onDatesChange={({ startDate, endDate }) =>                                                          
                            onChangeFilterDateRange(                                                                          
                              'filterDeliveryPickupDatetime',
                              startDate,
                              endDate,
                            )
                          }
                          focusedInput={
                            state.focusedInputDeliveryPickupDatetime
                          }
                          onFocusChange={(focusedInputDeliveryPickupDatetime) =>
                            setState({
                              ...state,
                              focusedInputDeliveryPickupDatetime,
                            })
                          }
                          isOutsideRange={(day) => false}
                          showClearDates={true}
                          minimumNights={0}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <button
                          className="ui button"
                          aria-label={intl.formatMessage(messages.filter)}
                          onClick={onClickFilter}
                        >
                          <Icon name={filterSVG} size="15px" />
                          {intl.formatMessage(messages.filter)}
                        </button>
                      </Table.Cell>
                    </Table.Row>
                    {orders.items &&
                     orders.items.map((order) => (                                                                            
                       <Table.Row key={order.id}>                                                                             
                         <Table.Cell>                                                                                         
                           <Link                                                                                              
                             className="item icon-align"                                                                      
                             to={`${baseUrl}/order?id=${order.id}`}                                                           
                           >                                                                                                  
                             {order.id}                                                                                       
                           </Link>                                                                                            
                         </Table.Cell>                                                                                        
                         {showShop && (                                                                                       
                           <Table.Cell>{order.shop_name}</Table.Cell>
                         )}                                                                                                   
                         <Table.Cell>{order.shipping_name}</Table.Cell>
                         {/*                                                                                                  
                            <Table.Cell>                                                                                      
                            <FormattedDate                                                                                    
                            date={order.orderDatetime}                                                                        
                            includeTime
                            />
                            </Table.Cell>
                          */}
                         <Table.Cell>
                           {getOrderStateTitle(order.state)}
                         </Table.Cell>
                         <Table.Cell>
                           {getDeliverOrPickup(order.deliver_or_pickup)}
                         </Table.Cell>
                         <Table.Cell>
                           <FormattedDate
                             date={order.delivery_pickup_datetime}
                             includeTime
                           />
                         </Table.Cell>
                       </Table.Row>
                     ))}
                  </Table.Body>
                </Table>
              </div>
              {orders?.batching && (
                <div className="contents-pagination">
                  <Pagination
                    current={state.currentPage}
                    total={Math.ceil(orders.total / state.pageSize)}
                    pageSize={state.pageSize}
                    pageSizes={[15, 30, 50]}
                    onChangePage={onChangePage}
                    onChangePageSize={onChangePageSize}
                  />
                </div>
              )}
            </Segment.Group>
          </section>
        </article>
      </Dimmer.Dimmable>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
OrdersView.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default OrdersView;
